import { types, flow } from "mobx-state-tree";
import { User } from "../models/User";
import { Socio } from "../models/Socio";
import { api } from "../api";
import { ActividadSocio } from "../models/ActividadSocio";
import { TipoMetodoPago } from "../models/TipoMetodoPago";
import { GeneroSocio } from "../models/GeneroSocio";
import { Banco } from "../models/Banco";
import { TipoSocio } from "../models/TipoSocio";

const RootStore = types
  .model({
    user: types.maybe(User),
    socios: types.optional(types.array(Socio), []),
    sociosDisponiblesGrupo: types.optional(types.array(Socio), []),
    actividades: types.optional(types.array(ActividadSocio), []),
    tipometodopagos: types.optional(types.array(TipoMetodoPago), []),
    generossocio: types.optional(types.array(GeneroSocio), []),
    bancos: types.optional(types.array(Banco), []),
    tiposocios: types.optional(types.array(TipoSocio), []),
    currentSocio: types.maybe(Socio),
  })
  .actions((self) => {
    return {
      getSocios: flow(function* () {
        try {
          const result = yield api.getSociosList();
          self.socios = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getBancos: flow(function* () {
        try {
          const result = yield api.getBancos();
          self.bancos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioActividades: flow(function* () {
        try {
          const result = yield api.getSocioActividades();
          self.actividades = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioTipoMetodoPagos: flow(function* () {
        try {
          const result = yield api.getSocioTipoMetodoPagos();
          self.tipometodopagos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioGeneros: flow(function* () {
        try {
          const result = yield api.getSocioGeneros();
          self.generossocio = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioTipoSocios: flow(function* () {
        try {
          const result = yield api.getSocioTipoSocios();
          self.tiposocios = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getOption: (option: string) => {
        switch (option) {
          case "genero":
            return self.generossocio;
          case "tipo_socio":
            return self.tiposocios;
          default:
            return [];
        }
      },
      setCurrentSocio: flow(function* (socioid: string) {
        try {
          const result = yield api.getSocio(socioid);
          self.currentSocio = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      refreshCurrentSocio: flow(function* () {
        try {
          if (self.currentSocio) {
            const result = yield api.getSocio(self.currentSocio.id + "");
            self.currentSocio = result.data;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      exportDocument: flow(function* (response: any) {
        try {
          const result = yield api.exportDocument(response);
          return result;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSociosDisponiblesGrupo: flow(function* (grupoid: any) {
        try {
          const result = yield api.getSociosDisponiblesGrupo(grupoid);
          self.sociosDisponiblesGrupo = result.data;
          return result;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
    };
  });
export default RootStore;
