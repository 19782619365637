import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { openInNewTab, getFilenameFromURL } from "../../utils/storage";
import { api } from "../../api";

import {
  Avatar,
  Button,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  withTheme,
} from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";

const ObsNutricionAdjuntosWrapper = withTheme(styled.div`
  display: flex;
  width: 100%;
  & .MuiList-root {
    width: 100%;
    cursor: pointer;
  }
  & .MuiAvatar-root {
    background: ${(props) => props.theme.palette.secondary.light};
  }
  & .MuiListItemText-root {
    color: #fff;
  }
`);

type IProps = {
  obs_id: number;
};

const ObsNutricionAdjuntos: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = React.useState(false);
  const [adjData, setadjData] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      const result = await api.getObsNutricionAdjuntos(props.obs_id);
      setadjData(result.data);
      setLoading(false);
    }
    fetchData();
  }, [loading]);

  if (loading) {
    return <LoadingDot height={"calc(100vh - 124px)"} />;
  }

  const handleClick = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const handleChange = (e: any) => {
    const change = api.addObsNutricionArchivoAdjunto(
      props.obs_id,
      e.target.files[0],
      handleUploadProgress
    );
  };
  const handleUploadProgress = (e: any) => {
    setProgress((e.loaded / e.total) * 100);
    if (e.loaded === e.total) {
      setLoading(true);
      api.getObsNutricionAdjuntos(props.obs_id).then(() => {
        setLoading(false);
      });
    }
  };
  const handleDelete = (adjuntoid: number) => {
    setLoading(true);
    const del = api
      .deleteObsNutricionAdjuntos(props.obs_id, adjuntoid)
      .then(() => {
        setLoading(false);
      });
  };
  return (
    <ObsNutricionAdjuntosWrapper data-testid="ObNutricionAdjuntos">
      {uploading && <LinearProgress variant="determinate" value={progress} />}
      <List>
        {adjData.map((option: any) => {
          if (option) {
            return (
              <ListItem key={option.id}>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={getFilenameFromURL(option.adjunto)}
                  onClick={() => openInNewTab(option.adjunto)}
                />
                <ListItemSecondaryAction
                  onClick={() => handleDelete(option.id)}
                >
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
        })}
        <ListItem>
          <Button onClick={handleClick}>
            <AddCircleIcon />
          </Button>
        </ListItem>
      </List>
      <form>
        <input
          type="file"
          id="archivo"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChange}
        />
      </form>
    </ObsNutricionAdjuntosWrapper>
  );
};
export default observer(ObsNutricionAdjuntos);
