import React from "react";
import FullWidthPage from "../FullWidthPage/FullWidthPage";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import {
  Breadcrumbs,
  Container,
  Typography,
  useTheme,
} from "@material-ui/core";
import SocioProfile from "../../components/SocioProfile/SocioProfile";
import { Link, RouteComponentProps } from "@reach/router";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const SocioProfileContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  color: #fff;
`;

type IProps = RouteComponentProps & { socioid?: any };

const SocioProfilePage: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();

  return (
    <FullWidthPage
      testid="SocioProfile"
      backgroundColor={theme.palette.secondary.dark}
    >
      <SocioProfileContainer maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <HomeIcon />
          </Link>
          <Link color="inherit" to="/socios/">
            <PeopleAltIcon />
          </Link>
          <Typography color="textPrimary">
            <AssignmentIndIcon />
            {t("Perfil de Socio ")}
            {rootStore.currentSocio?.nombre +
              " " +
              rootStore.currentSocio?.apellido}
          </Typography>
        </Breadcrumbs>
        <SocioProfile socioid={props.socioid} />
      </SocioProfileContainer>
    </FullWidthPage>
  );
};

export default observer(SocioProfilePage);
