import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withTheme,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { SocioFormInput } from "../../types";
import { api } from "../../api";
import { addServerErrors } from "../../utils/storage";

const SocioFormWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const StyledForm = withTheme(styled("form")`
  width: 100%;
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  }
`);
const StyledMenuItem = withTheme(styled(MenuItem)`
  && {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
const StyledSelectFormControl = styled(FormControl)`
  width: 80%;
`;
const StyledTextField = withTheme(styled(TextField)`
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

const generos = [
  { label: "Masculino", value: 1 },
  { label: "Femenino", value: 2 },
  { label: "Otro", value: 3 },
];
const paises = [
  { label: "Argentina", value: 1 },
  { label: "Brasil", value: 2 },
  { label: "Otro", value: 3 },
];
const provincias = [
  { label: "Buenos Aires", value: 1 },
  { label: "La Pampa", value: 2 },
  { label: "Otro", value: 3 },
];
type IProps = {};

const SocioForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SocioFormInput>();
  const onSubmit = async (data: SocioFormInput) => {
    const result = await api.createSocio(data);
    if (result) {
      if (result.status === 201) {
        navigate(`/socio/${result.data.id}`);
      } else {
        addServerErrors(result.data, setError);
      }
    }
  };
  return (
    <SocioFormWrapper data-testid="SocioForm">
      <StyledForm data-testid="SocioForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="nombre"
                  label={t("Nombre")}
                  error={errors.nombre !== undefined}
                  helperText={errors.nombre?.message}
                  {...field}
                />
              )}
              name="nombre"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                minLength: {
                  value: 2,
                  message: t("Nombre debe tener mínimo 2 caracteres."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="apellido"
                  label={t("Apellido")}
                  error={errors.apellido !== undefined}
                  helperText={errors.apellido?.message}
                  {...field}
                />
              )}
              name="apellido"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                minLength: {
                  value: 2,
                  message: t("Apellido debe tener mínimo 2 caracteres."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="numero"
                  label={t("Número de Socio")}
                  error={errors.numero !== undefined}
                  helperText={errors.numero?.message}
                  {...field}
                />
              )}
              name="numero"
              control={control}
              defaultValue={0}
              rules={{
                required: true,
                pattern: {
                  value: /[0-9]+/,
                  message: t("Por favor ingrese sólo números."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => {
                return (
                  <StyledSelectFormControl>
                    <InputLabel id="genero">{t("Género")}</InputLabel>
                    <Select
                      id="genero"
                      error={errors.genero !== undefined}
                      {...field}
                    >
                      {generos.map((option) => (
                        <StyledMenuItem value={option.value}>
                          {option.label}
                        </StyledMenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.genero?.message}</FormHelperText>
                  </StyledSelectFormControl>
                );
              }}
              name="genero"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="dni"
                  label={t("Número de DNI")}
                  error={errors.dni !== undefined}
                  helperText={errors.dni?.message}
                  {...field}
                />
              )}
              name="dni"
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 7,
                  message: t("DNI debe tener mínimo 7 caracteres."),
                },
                pattern: {
                  value: /[0-9]+/,
                  message: t("Por favor ingrese sólo números."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="direccion"
                  label={t("Dirección")}
                  error={errors.direccion !== undefined}
                  helperText={errors.direccion?.message}
                  {...field}
                />
              )}
              name="direccion"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="email"
                  label={t("Email")}
                  error={errors.email !== undefined}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: t("Por favor ingrese un email válido."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="telefono_linea"
                  label={t("Teléfono de Linea")}
                  error={errors.telefono_linea !== undefined}
                  helperText={errors.telefono_linea?.message}
                  {...field}
                />
              )}
              name="telefono_linea"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: /[0-9]+/,
                  message: t("Por favor ingrese sólo números."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="telefono_celular"
                  label={t("Teléfono Celular")}
                  error={errors.telefono_celular !== undefined}
                  helperText={errors.telefono_celular?.message}
                  {...field}
                />
              )}
              name="telefono_celular"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: /[0-9]+/,
                  message: t("Por favor ingrese sólo números."),
                },
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="fecha_nacimiento"
                  label={t("Fecha Nacimiento")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.fecha_nacimiento !== undefined}
                  helperText={errors.fecha_nacimiento?.message}
                  {...field}
                />
              )}
              name="fecha_nacimiento"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="codigo_postal"
                  label={t("Código Postal")}
                  error={errors.codigo_postal !== undefined}
                  helperText={errors.codigo_postal?.message}
                  {...field}
                />
              )}
              name="codigo_postal"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => {
                return (
                  <StyledSelectFormControl>
                    <InputLabel id="pais">{t("País")}</InputLabel>
                    <Select
                      id="pais"
                      error={errors.pais !== undefined}
                      {...field}
                    >
                      {paises.map((option) => (
                        <StyledMenuItem value={option.value}>
                          {option.label}
                        </StyledMenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.pais?.message}</FormHelperText>
                  </StyledSelectFormControl>
                );
              }}
              name="pais"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              render={({ field }) => {
                return (
                  <StyledSelectFormControl>
                    <InputLabel id="provincia">{t("Provincia")}</InputLabel>
                    <Select
                      id="provincia"
                      error={errors.provincia !== undefined}
                      {...field}
                    >
                      {provincias.map((option) => (
                        <StyledMenuItem value={option.value}>
                          {option.label}
                        </StyledMenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.provincia?.message}</FormHelperText>
                  </StyledSelectFormControl>
                );
              }}
              name="provincia"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item md={9} xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Agregar
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </SocioFormWrapper>
  );
};
export default observer(SocioForm);
