import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import {
  ButtonProps,
  Card,
  Grid,
  List,
  ListItem,
  withTheme,
} from "@material-ui/core";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailIcon from "@material-ui/icons/Mail";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ToggleInput from "../ToggleInput/ToggleInput";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import ReactTooltip from "react-tooltip";
import ToggleSelect from "../ToggleSelect/ToggleSelect";

const SocioCardWrapper = styled.div`
  display: flex;
  flex-orientation: column;
  width: 100%;
  padding-top: 40px;
`;

const StyledCard = withTheme(styled(Card)`
  width: 100%;
  height: 250px;
  color: ${(props) => props.theme.palette.secondary.dark} !important;
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 200;
  & .MuiListItem-root {
    justify-content: flex-end;
  }
  & strong {
    cursor: pointer;
  }
  & .MuiListItem-root a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.secondary.light};
    height: 24px;
    line-height: 24px;
    display: flex;
    width: 60%;
    padding-left: 10px;
  }
  & .MuiListItem-root {
    height: 40px;
  }
`);
type StyledButtonProps = ButtonProps & { $valido?: boolean };

const LongListItem = styled(ListItem)`
  && strong {
    width: 90%;
  }
  && a {
    width: 90%;
  }
` as React.ComponentType<ButtonProps>;

const BooleanListItem = styled(ListItem)<StyledButtonProps>`
  && {
    text-decoration: ${(props) => (props.$valido ? "none" : "line-through")};
    color: ${(props) => (props.$valido ? "green" : "red")};
    font-size: 0.8rem;
  }
  & .MuiSvgIcon-root {
    font-size: 0.8rem;
    padding-right: 5px;
  }
` as React.ComponentType<StyledButtonProps>;

const MidGrid = styled(Grid)`
  && {
    & .MuiListItem-root {
      justify-content: flex-start;
    }
  }
`;

type IProps = {};

const SocioCard: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const CursorPointer = { cursor: "pointer" };
  const OneRemIcon = { fontSize: "1rem" };

  return (
    <SocioCardWrapper data-testid="SocioCard">
      <ReactTooltip />
      <StyledCard variant="outlined">
        <Grid container>
          <Grid item md={4} xs={12}>
            <List>
              <ListItem>
                {t("Socio #")}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.numero
                      ? rootStore.currentSocio?.numero
                      : "Sin datos."
                  }
                  type="text"
                  id="numero"
                />
              </ListItem>
              <ListItem>
                {t("Inscripción")}: {"  "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.fecha_inscripcion
                      ? rootStore.currentSocio?.fecha_inscripcion
                      : "Sin datos."
                  }
                  type="date"
                  id="fecha_inscripcion"
                />
              </ListItem>
              <ListItem>
                {t("DNI")}:{" "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.dni
                      ? rootStore.currentSocio?.dni
                      : "Sin datos."
                  }
                  type="text"
                  id="dni"
                />
              </ListItem>
              <ListItem>
                {t("Nacimiento")}:{"  "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.fecha_nacimiento
                      ? rootStore.currentSocio?.fecha_nacimiento
                      : "Sin datos."
                  }
                  type="date"
                  id="fecha_nacimiento"
                />
              </ListItem>
              <ListItem>
                {t("Género")}:{" "}
                <ToggleSelect
                  initialValue={
                    rootStore.currentSocio?.genero
                      ? rootStore.currentSocio?.genero
                      : "Sin datos."
                  }
                  id="genero"
                  getOptions={rootStore.getSocioGeneros}
                />
              </ListItem>
            </List>
          </Grid>
          <MidGrid item md={4} xs={12}>
            <List>
              <LongListItem>
                <MailIcon
                  onClick={() => {
                    if (rootStore.currentSocio?.email) {
                      window.open(`mailto:${rootStore.currentSocio?.email}`);
                      return true;
                    }
                    return false;
                  }}
                  style={CursorPointer}
                  data-tip={t("Hace click para enviar un correo electronico.")}
                />
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.email
                      ? rootStore.currentSocio?.email
                      : "Sin datos."
                  }
                  type="text"
                  id="email"
                />
              </LongListItem>
              <LongListItem>
                <PhoneInTalkIcon
                  onClick={() => {
                    if (rootStore.currentSocio?.email) {
                      window.open(
                        `https://wa.me/${rootStore.currentSocio?.telefono_celular}`
                      );
                      return true;
                    }
                    return false;
                  }}
                  style={CursorPointer}
                  data-tip={t("Hace click para enviar un WhatsApp.")}
                />
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.telefono_celular
                      ? rootStore.currentSocio?.telefono_celular
                      : "Sin datos."
                  }
                  type="text"
                  id="telefono_celular"
                />
              </LongListItem>
              <LongListItem>
                <HomeWorkIcon />{" "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.direccion
                      ? rootStore.currentSocio?.direccion
                      : "Sin datos."
                  }
                  type="text"
                  id="direccion"
                />
              </LongListItem>
              <LongListItem>
                <HomeWorkIcon />{" "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.localidad
                      ? rootStore.currentSocio?.localidad
                      : "Sin datos."
                  }
                  type="text"
                  id="localidad"
                />
              </LongListItem>
              <LongListItem>
                <MarkunreadMailboxIcon />{" "}
                <ToggleInput
                  initialValue={
                    rootStore.currentSocio?.codigo_postal
                      ? rootStore.currentSocio?.codigo_postal
                      : "Sin datos."
                  }
                  type="text"
                  id="codigo_postal"
                />
              </LongListItem>
            </List>
          </MidGrid>
          <Grid item md={4} xs={12}>
            <List>
              <BooleanListItem $valido={true}>
                <CheckCircleIcon style={OneRemIcon} />
                {t("Derechos de imagen")}
              </BooleanListItem>
              <BooleanListItem $valido={false}>
                <CancelIcon style={OneRemIcon} />
                {t("Recibir correspondencia postal")}
              </BooleanListItem>
              <BooleanListItem $valido={true}>
                <CheckCircleIcon style={OneRemIcon} />
                {t("Recibir correspondencia vía e-mail")}
              </BooleanListItem>
              <BooleanListItem $valido={false}>
                <CancelIcon style={OneRemIcon} />
                {t("Declaración COVID-19")}
              </BooleanListItem>
            </List>
          </Grid>
        </Grid>
      </StyledCard>
    </SocioCardWrapper>
  );
};
export default observer(SocioCard);
