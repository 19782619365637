import React, { useEffect, useState } from "react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import { observer } from "mobx-react";
import FilteringGrid from "../DataGrid/DataGrid";
import LoadingDot from "../LoadingDot/LoadingDot";

type SocioListProps = {};

const SocioList: React.FC<SocioListProps> = (props: any) => {
  const rootStore = useStore(RootStore);
  const [socios, setSocios] = useState<any | null>([]);
  const [loading, setLoading] = React.useState(true);
  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "apellido",
      label: "Apellido",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dni",
      label: "DNI",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "direccion",
      label: "Direccion",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "telefono_linea",
      label: "Telefono Linea",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "telefono_celular",
      label: "Telefono Celular",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "fecha_nacimiento",
      label: "Fecha Nacimiento",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "codigo_postal",
      label: "Codigo Postal",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "pais",
      label: "Pais",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "provincia",
      label: "Provincia",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "genero",
      label: "Genero",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "tipo_socio",
      label: "Tipo Socio",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "fecha_inscripcion",
      label: "Fecha Inscrpcion",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "localidad",
      label: "Localidad",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];
  const title = "Lista de socios";

  useEffect(() => {
    setLoading(true);
    if (rootStore !== null) {
      rootStore.getSocios().then(() => {
        setSocios(rootStore.socios);
        setLoading(false);
      });
    }
  }, [rootStore]);

  if (loading) {
    return <LoadingDot height={"calc(100vh - 124px)"} />;
  }

  return <FilteringGrid columns={columns} data={socios} title={title} />;
};

export default observer(SocioList);
