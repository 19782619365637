import { withTheme } from "@material-ui/core";
import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

interface ILoadingDotWrapperStyleProps {
  height?: string;
}
const LoadingDotWrapper = withTheme(styled.div<ILoadingDotWrapperStyleProps>`
  font-family: sans-serif;
  text-align: center;
  background-color: transparent;
  position: relative;
  height: ${(props) => (props.height ? props.height : "100vh")};
  width: 100%;
`);

const LoadingDotDiv = withTheme(styled(animated.div)`
  border: 10px solid ${(props) => props.theme.palette.primary.dark};
  border-radius: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  background-color: ${(props) => props.theme.palette.primary.light};
`);

const convertStyles = (styles: any) => ({
  height: styles.diameter,
  width: styles.diameter,
  opacity: styles.opacity,
});

type IProps = {
  height?: string;
};

const LoadingDot: React.FC<IProps> = (props) => {
  const styles = useSpring({
    reset: true,
    loop: true,
    from: { opacity: 1, diameter: 0 },
    to: { opacity: 0, diameter: 300 },
    config: {
      tension: 280,
      friction: 60,
    },
  });
  return (
    <LoadingDotWrapper height={props.height}>
      <LoadingDotDiv style={convertStyles(styles)}></LoadingDotDiv>
    </LoadingDotWrapper>
  );
};
export default LoadingDot;
