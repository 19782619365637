import React from "react";
import FullWidthPage from "../FullWidthPage/FullWidthPage";
import styled from "styled-components";
import { Container, useTheme } from "@material-ui/core";
import Dashboard from "../../components/Dashboard/Dashboard";

const HomeContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  color: #fff;
`;
type IProps = {
  path?: string;
};

const HomePage: React.FC<IProps> = (props) => {
  const theme = useTheme();

  return (
    <FullWidthPage
      testid="HomePage"
      backgroundColor={theme.palette.secondary.dark}
    >
      <HomeContainer maxWidth="lg">
        <Dashboard />
      </HomeContainer>
    </FullWidthPage>
  );
};

export default HomePage;
