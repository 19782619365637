import React from "react";
import { useTranslation } from "react-i18next";
import FullWidthPage from "../FullWidthPage/FullWidthPage";
import styled from "styled-components";
import {
  Breadcrumbs,
  Container,
  Typography,
  useTheme,
} from "@material-ui/core";
import SocioForm from "../../components/SocioForm/SocioForm";
import { Link } from "@reach/router";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import GrainIcon from "@material-ui/icons/Grain";

const SocioAddContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  color: #fff;
`;
type IProps = {
  path?: string;
};

const SocioAdd: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FullWidthPage
      testid="HomePage"
      backgroundColor={theme.palette.secondary.dark}
    >
      <SocioAddContainer maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <HomeIcon />
          </Link>
          <Link color="inherit" to="/socios/">
            <PeopleAltIcon />
          </Link>
          <Typography color="textPrimary">
            <GrainIcon />
            {t("Agregar Socio")}
          </Typography>
        </Breadcrumbs>

        <SocioForm />
      </SocioAddContainer>
    </FullWidthPage>
  );
};

export default SocioAdd;
