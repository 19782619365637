import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Grid, Hidden, Typography } from "@material-ui/core";
import SocioCard from "../SocioCard/SocioCard";
import SocioProfileGrupo from "../SocioProfileGrupo/SocioProfileGrupo";
import SocioProfileTabs from "../SocioProfileTabs/SocioProfileTabs";
import SocioActividad from "../SocioActividad/SocioActividad";
import LoadingDot from "../LoadingDot/LoadingDot";
import SocioProfileMenu from "../SocioProfileMenu/SocioProfileMenu";

const SocioProfileWrapper = styled.div`
  display: flex;
`;
interface IProps {
  socioid?: any;
}

const SocioProfile: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const rootStore = useStore(RootStore);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    rootStore.setCurrentSocio(props.socioid).then(() => {
      setLoading(false);
    });
  }, [props.socioid]);

  if (loading) {
    return <LoadingDot height={"calc(100vh - 124px)"} />;
  }

  return (
    <SocioProfileWrapper data-testid="SocioProfile">
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SocioProfileMenu />
        </Grid>
        <Grid item xs={9} lg={7}>
          <SocioCard />
          {rootStore.currentSocio?.grupo && <SocioProfileGrupo />}
          <SocioProfileTabs />
        </Grid>
        <Hidden lgDown>
          <Grid item xs={2}>
            <SocioActividad />
          </Grid>
        </Hidden>
      </Grid>
    </SocioProfileWrapper>
  );
};
export default observer(SocioProfile);
