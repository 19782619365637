import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";
import { AppBar, Card, Tab, Tabs, withTheme } from "@material-ui/core";
import SocioProfilePanelActividades from "../SocioProfilePanelActividades/SocioProfilePanelActividades";
import SocioProfilePanelPagos from "../SocioProfilePanelPagos/SocioProfilePanelPagos";
import SocioProfilePanelPsicologia from "../SocioProfilePanelPsicologia/SocioProfilePanelPsicologia";
import SocioProfilePanelNutricion from "../SocioProfilePanelNutricion/SocioProfilePanelNutricion";

const SocioProfileTabsWrapper = styled.div`
  display: flex;
  flex-orientation: column;
  width: 100%;
  padding-top: 20px;
  & header {
    border-radius: 4px;
  }
`;
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FF0000",
    },
    secondary: {
      main: "#000",
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        backgroundColor: "#eee",
      },
    },
    MuiDrawer: {
      paper: {
        padding: "1%",
      },
    },
  },
});
type IProps = {};

const SocioProfileTabs: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <SocioProfileTabsWrapper data-testid="SocioTabs">
      <ThemeProvider theme={theme}>
        <AppBar position="static" color="primary">
          <Tabs value={tab} onChange={handleChange}>
            <Tab label={t("Actividades")} />
            <Tab label={t("Pagos")} />
            <Tab label={t("Psicología")} />
            <Tab label={t("Nutrición")} />
          </Tabs>
          {tab === 0 && <SocioProfilePanelActividades />}
          {tab === 1 && <SocioProfilePanelPagos />}
          {tab === 2 && <SocioProfilePanelPsicologia />}
          {tab === 3 && <SocioProfilePanelNutricion />}
        </AppBar>
      </ThemeProvider>
    </SocioProfileTabsWrapper>
  );
};
export default observer(SocioProfileTabs);
