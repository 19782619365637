import { Container, Typography, withTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const FooterWrapper = withTheme(styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.51);
  align-items: center;
`);

type IProps = {};

const Footer: React.FC<IProps> = (props) => {


  return (
    <FooterWrapper data-testid="Footer">
      <Container maxWidth="lg">
        <Typography variant="body2" color="textPrimary">
          2021
        </Typography>
      </Container>
    </FooterWrapper>
  );
};
export default Footer;
