import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import LoadingDot from "../LoadingDot/LoadingDot";
import { Grid } from "@material-ui/core";
import { Nutricion } from "../../models/Nutricion";
import AppConfig from "../../config";
import { nicerName } from "../../utils/storage";
import ToggleInput from "../ToggleInput/ToggleInput";

const SocioProfilePanelMedidasWrapper = styled.div`
  display: flex;
`;
const StyledGrid = styled(Grid)`
  padding-top: 40px;
  font-family: "Roboto";
  font-weight: 300;
`;

type IProps = {};

const SocioProfilePanelMedidas: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    rootStore.currentSocio?.getSocioNutricion().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingDot height={"calc(100vh - 124px)"} />;
  }

  return (
    <SocioProfilePanelMedidasWrapper data-testid="SocioProfilePanelMedidas">
      <StyledGrid container spacing={4}>
        {Object.entries(Nutricion.properties).map((key) => {
          const n = rootStore.currentSocio!.nutricion;
          if (key[0] != "id" && key[0] != "enfermedades") {
            return (
              <Grid item key={key[0]} xs={3}>
                <span>{nicerName(key[0])}</span>
                <ToggleInput
                  type="text"
                  id={key[0]}
                  initialValue={n[key[0] as keyof typeof n]}
                  patchFunction={rootStore.currentSocio?.patchNutricion}
                  needs_group={AppConfig.PERMISO_NUTRICION}
                />
              </Grid>
            );
          }
        })}
      </StyledGrid>
    </SocioProfilePanelMedidasWrapper>
  );
};
export default observer(SocioProfilePanelMedidas);
