import { ActividadSocio } from "../models/ActividadSocio";

export const ActividadSocioTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}
  if (data !== undefined) {
    return ActividadSocio.create({
      id: data.id,
      nombre: `${data.nombre.nombre} ${data.genero.nombre} ${data.categoria.nombre} (${data.iu})`,
    });
  }
};
