import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";
import { api } from "../../api";
import ReactTooltip from "react-tooltip";

const SocioProfileMenuAvatarWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
`;
const StyledAvatar = styled(Avatar)`
  && {
    width: 100%;
    height: 100%;
  }
`;

type IProps = {};

const SocioProfileMenuAvatar: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const inputFile = React.useRef<HTMLInputElement>(null);

  const handleClick = (e: any) => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const handleChange = (e: any) => {
    setLoading(true);
    const result = api
      .patchSocio(rootStore.currentSocio?.id, "foto", e.target.files[0], true)
      .then(() => {
        rootStore.refreshCurrentSocio().then(() => {
          setLoading(false);
        });
      });
  };
  if (loading) {
    return <LoadingDot height={"calc(125px)"} />;
  }
  return (
    <SocioProfileMenuAvatarWrapper data-testid="SocioProfileMenuAvatar">
      <ReactTooltip />
      <StyledAvatar
        onClick={handleClick}
        alt={rootStore.currentSocio?.nombre || ""}
        src={rootStore.currentSocio?.foto || ""}
        data-tip={t("Hace click para reemplazar la foto.")}
      />
      <form>
        <input
          type="file"
          id="foto"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChange}
          accept="image/*"
        />
      </form>
    </SocioProfileMenuAvatarWrapper>
  );
};
export default observer(SocioProfileMenuAvatar);
