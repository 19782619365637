import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  withTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { navigate } from "@reach/router";
import { api } from "../../api";
import AppConfig from "../../config";
const HeaderSearchWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledTextField = withTheme(styled(TextField)`
  background-color: ${(props) => props.theme.palette.primary.light};
  line-height: 64px;
  width: 220px;
  border-radius: 5px;
  & .MuiSvgIcon-root {
    padding: 5px;
    color: #fff;
  }
  & input {
    color: #fff;
  }
  & input::placeholder {
    color: #fff;
  }
  & .MuiInput-underline:before {
    border-bottom: none;
  }
`);

const StyledList = withTheme(styled(List)`
  && {
    background-color: #fff;
    width: 220px;
    position: absolute;
    top: 32px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    & .MuiListItemText-primary {
      color: ${(props) => props.theme.palette.secondary.dark};
    }
    & .MuiListItemText-secondary {
      color: ${(props) => props.theme.palette.secondary.light};
    }
  }
`);

interface SocioType {
  dni: number;
  apellido: string;
  nombre: string;
  id: number;
  numero: number;
  foto: string;
}

type IProps = {};

const HeaderSearch: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [options, setOptions] = React.useState<SocioType[]>([]);
  let loading = false;

  const handleChange = (e: any) => {
    setText(e.target.value);
    if (text.length > 1) {
      searchSocios();
    } else {
      setOpen(false);
    }
  };
  const handleClick = (path: string) => {
    setOpen(false);
    setText("");
    navigate(path);
  };

  const searchSocios = async () => {
    loading = true;
    const socios = await api.getSociosAutocomplete(text);
    const arr: SocioType[] = new Array<SocioType>();
    if (socios.data.length > 0) {
      Object.keys(socios.data).map(function (key, index) {
        arr.push(socios.data[index]);
        return true;
      });
      setOptions(arr);
      setOpen(true);
    } else {
      setOptions([]);
      setOpen(false);
    }
    loading = false;
  };

  return (
    <HeaderSearchWrapper data-testid="HeaderSearch">
      <StyledTextField
        onChange={handleChange}
        placeholder="Buscar Socio"
        value={text}
        InputProps={{
          startAdornment: <SearchIcon />,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={10} /> : null}
            </React.Fragment>
          ),
        }}
      />
      {open && (
        <StyledList dense>
          {options.map((value) => {
            const labelId = `list-label-${value.id}`;
            return (
              <ListItem
                key={value.id}
                button
                onClick={() => handleClick(`/socio/${value.id}`)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${value.id}`}
                    src={`${AppConfig.API_BASE_URL}${value.foto}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={`${value.nombre} ${value.apellido}`}
                  secondary={`DNI: ${value.dni}`}
                />
              </ListItem>
            );
          })}
        </StyledList>
      )}
    </HeaderSearchWrapper>
  );
};
export default HeaderSearch;
