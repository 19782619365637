import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import MUIDataTable, { FilterType } from "mui-datatables";
import { Drawer, Typography, withTheme } from "@material-ui/core";
import AppConfig from "../../config";
import CustomToolbar from "../DataGrid/CustomToolbar";
import SocioPagosForm from "../SocioPagosForm/SocioPagosForm";

const SocioProfilePanelPagosWrapper = styled.div``;
const DrawerWrapper = withTheme(styled(Drawer)``);
const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
    },
  },
  {
    name: "metodo",
    label: "Método",
  },
  {
    name: "monto",
    label: "Monto",
  },
  {
    name: "fecha",
    label: "Fecha",
  },
];

type IProps = {};

const SocioProfilePanelPagos: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const data = rootStore.currentSocio?.pagos;
  const [open, setOpen] = React.useState(false);
  const create = () => {
    setOpen(true);
  };
  const options = {
    filterType: "textField" as FilterType,
    textLabels: AppConfig.DATATABLE_LABELS,
    customToolbar: () => {
      return <CustomToolbar handleClickCreate={create} />;
    },
  };
  return (
    <>
      <SocioProfilePanelPagosWrapper data-testid="SocioProfilePanelPagos">
        <MUIDataTable
          title={t("Lista de Pagos")}
          data={data ? data : []}
          columns={columns}
          options={options}
        />
      </SocioProfilePanelPagosWrapper>
      <DrawerWrapper anchor="left" open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5" align="center">
          {t("Agregar Pago")}
        </Typography>
        <SocioPagosForm closeback={setOpen} />
      </DrawerWrapper>
    </>
  );
};
export default observer(SocioProfilePanelPagos);
