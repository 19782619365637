import { Redirect, RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import { useStore } from "mobx-store-provider";
import VolatileStore from "../../stores/VolatileStore";
import { observer } from "mobx-react";

type Props = RouteComponentProps & { as: FunctionComponent };

const AuthenticatedRoute: FunctionComponent<Props> = ({
  as: Component,
  ...props
}) => {
  const volatileStore = useStore(VolatileStore);
  const { ...rest } = props;

  return volatileStore.isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Redirect from="" to="/login" noThrow />
  );
};

export default observer(AuthenticatedRoute);
