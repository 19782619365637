import { SocioTransformer } from "./SocioTransformer";

export const SociosTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}

  const socios: any[] = [];
  if (data !== undefined) {
    for (let socio of data) {
      socios.push(SocioTransformer(socio));
    }
  }
  return socios;
};
