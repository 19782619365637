import React from "react";
import MUIDataTable from "mui-datatables";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import CustomToolbar from "./CustomToolbar";
import { navigate } from "@reach/router";
import AppConfig from "../../config";

export default function FilteringGrid(props: any) {
  var selectedRows: string | any[];
  const { columns, data, title } = props;
  var excludedColumns: any[] = columns.map((col: any, index: any) => {
    if (col.options.display === false) {
      return col.name;
    } else {
      return "";
    }
  });
  const rootStore = useStore(RootStore);
  const response = {
    socios: [] as any,
    formato: "",
    excludedColumns: [] as any,
  };

  const exportPdf = () => {
    if (selectedRows !== undefined) {
      var auxArray = [];
      for (var i = 0; i < selectedRows.length; i++) {
        auxArray.push(data[selectedRows[i]].id);
      }
      rootStore.exportDocument({
        ...response,
        socios: auxArray,
        formato: "pdf",
        excludedColumns: excludedColumns,
      });
    }
  };

  const exportExcel = () => {
    if (selectedRows !== undefined) {
      var auxArray = [];
      for (var i = 0; i < selectedRows.length; i++) {
        auxArray.push(data[selectedRows[i]].id);
      }
      rootStore.exportDocument({
        ...response,
        socios: auxArray,
        formato: "excel",
        excludedColumns: excludedColumns,
      });
    }
  };

  const createSocio = () => {
    navigate("/socios/nuevo");
  };

  const options = {
    download: false,
    filter: true as any,
    selectToolbarPlacement: "none" as any,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === "remove") excludedColumns.push(changedColumn);
      else {
        var index = excludedColumns.indexOf(changedColumn);
        if (index !== -1) {
          excludedColumns.splice(index, 1);
        }
      }
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      navigate(`/socio/${data[rowMeta.rowIndex].id}`);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      selectedRows = rowsSelected;
    },
    filterType: "textField" as any,
    responsive: "vertical" as any,
    customToolbar: () => {
      return (
        <CustomToolbar
          handleClickPdf={exportPdf}
          handleClickExcel={exportExcel}
          handleClickCreate={createSocio}
        />
      );
    },
    textLabels: AppConfig.DATATABLE_LABELS,
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
}
