import axios from "./axios";
import { ActividadesSocioTransformer } from "./transformers/ActividadesSocioTransformer";
import { SociosTransformer } from "./transformers/SociosTransformer";
import { SocioTransformer } from "./transformers/SocioTransformer";
import { UserTransformer } from "./transformers/UserTransformer";

import {
  LoginFormInput,
  SocioFormInput,
  SocioActividadFormInput,
  SocioPagosFormInput,
  SocioMetodoPagoFormInput,
} from "./types";

export const api = {
  deleteObsNutricionAdjuntos: (obsid: number, adjuntoid: number) => {
    return axios.request({
      url: `/api/obs_nutricion/${obsid}/adjuntos/`,
      method: "DELETE",
      data: {
        id: adjuntoid,
      },
    });
  },
  getObsNutricionAdjuntos: (id: number) => {
    return axios.request({
      url: `/api/obs_nutricion/${id}/adjuntos/`,
      method: "GET",
    });
  },
  addObsNutricionArchivoAdjunto: (
    obsid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    form_data.append("adjunto", validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/obs_nutricion/${obsid}/adjuntos/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  patchObsNutricionbyID: (id: number | undefined, data: any) => {
    return axios.request({
      url: `/api/obs_nutricion/${id}/`,
      method: "PATCH",
      data: {
        observaciones: data.observaciones,
        fecha: data.fecha,
      },
    });
  },
  postObsNutricion: (
    data: any,
    nutricionsocio_id: number | null | undefined
  ) => {
    return axios.request({
      url: "/api/obs_nutricion/",
      method: "POST",
      data: {
        nutricion_socio: nutricionsocio_id,
        observaciones: data.observaciones,
        fecha: data.fecha,
      },
    });
  },
  getObsNutricion: (socio_id: number | undefined) => {
    return axios.request({
      url: `/api/obs_nutricion?socio_id=${socio_id}`,
      method: "GET",
    });
  },
  deleteObsPsicologiaAdjuntos: (obsid: number, adjuntoid: number) => {
    return axios.request({
      url: `/api/obs_psicologia/${obsid}/adjuntos/`,
      method: "DELETE",
      data: {
        id: adjuntoid,
      },
    });
  },
  getObsPsicologiaAdjuntos: (id: number) => {
    return axios.request({
      url: `/api/obs_psicologia/${id}/adjuntos/`,
      method: "GET",
    });
  },
  addObsPsicologiaArchivoAdjunto: (
    obsid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    form_data.append("adjunto", validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/obs_psicologia/${obsid}/adjuntos/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  patchObsPsicologiabyID: (id: number | undefined, data: any) => {
    return axios.request({
      url: `/api/obs_psicologia/${id}/`,
      method: "PATCH",
      data: {
        observaciones: data.observaciones,
        fecha: data.fecha,
      },
    });
  },
  getObsPsicologia: (socio_id: number | undefined) => {
    return axios.request({
      url: `/api/obs_psicologia?socio_id=${socio_id}`,
      method: "GET",
    });
  },
  postObsPsicologia: (
    data: any,
    psicologiasocio_id: number | null | undefined
  ) => {
    return axios.request({
      url: "/api/obs_psicologia/",
      method: "POST",
      data: {
        psicologia_socio: psicologiasocio_id,
        observaciones: data.observaciones,
        fecha: data.fecha,
      },
    });
  },
  getChartData: () => {
    return axios.request({
      url: "/api/charts/",
      method: "GET",
    });
  },
  login: (validatedFormData: LoginFormInput) => {
    return axios.request({
      url: "/api/auth/login/",
      method: "POST",
      data: {
        username: validatedFormData.username,
        password: validatedFormData.password,
      },
      transformResponse: [
        function (data) {
          return UserTransformer(data);
        },
      ],
    });
  },
  logout: () => {
    return axios.request({
      url: "/api/auth/logout/",
      method: "POST",
    });
  },
  verify: () => {
    return axios
      .request({
        url: "/api/auth/user/",
        method: "GET",
        transformResponse: [
          function (data) {
            if (data) {
              return UserTransformer(data);
            } else {
              return false;
            }
          },
        ],
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  getSociosList: () => {
    return axios.request({
      url: "/api/socios/",
      method: "GET",
      transformResponse: [
        function (data) {
          return SociosTransformer(data);
        },
      ],
    });
  },
  getSocio: (socioid: string) => {
    return axios.request({
      url: `/api/socios/${socioid}`,
      method: "GET",
      transformResponse: [
        function (data) {
          return SocioTransformer(data);
        },
      ],
    });
  },
  getSociosAutocomplete: (query: string) => {
    return axios.request({
      url: `/api/socios/autocomplete/?query=${query}`,
      method: "GET",
    });
  },
  exportDocument: (response: any) => {
    return axios
      .post("/api/descargar_documento/", response, {
        responseType: "blob",
      })
      .then((documentResponse: any) => {
        const blob = new Blob([documentResponse.data], {
          type:
            response.formato === "excel"
              ? "application/ms-excel"
              : "application/pdf",
        });
        const objectUrl = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = objectUrl;
        if (response.formato === "excel") fileLink.download = "report.xlsx";
        else fileLink.download = "report.pdf";
        fileLink.click();
      })
      .catch((error: any) => {
        alert(error);
      });
  },
  createSocio: (validatedFormData: SocioFormInput) => {
    return axios
      .request({
        url: "/api/socios/",
        method: "POST",
        data: validatedFormData,
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  patchSocio: (
    socioid: number | undefined,
    field: string,
    value: any,
    file = false
  ) => {
    let data = {};
    let headers = "application/json";
    if (file) {
      let form_data = new FormData();
      form_data.append(field, value, value.name);
      headers = "multipart/form-data";
      data = form_data;
    } else {
      data = {
        [field]: value,
      };
    }
    return axios.request({
      url: `/api/socios/${socioid}/`,
      method: "PATCH",
      headers: {
        "Content-Type": headers,
      },
      data: data,
    });
  },
  patchNutricion: (socioid: number | undefined, field: string, value: any) => {
    return axios.request({
      url: `/api/socios/${socioid}/nutricion/`,
      method: "PATCH",
      data: {
        [field]: value,
      },
    });
  },
  getSocioActividades: () => {
    return axios.request({
      url: "/api/actividades/",
      method: "GET",
      transformResponse: [
        function (data) {
          return ActividadesSocioTransformer(data);
        },
      ],
    });
  },
  getSocioTipoSocios: () => {
    return axios.request({
      url: "/api/tipos_socio/",
      method: "GET",
    });
  },
  addSocioActividades: (
    socioid: number | undefined,
    validatedFormData: SocioActividadFormInput
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/agregar_actividad/`,
      method: "PATCH",
      data: {
        actividad_id: validatedFormData.id,
      },
    });
  },
  getSocioTipoMetodoPagos: () => {
    return axios.request({
      url: "/api/tipos_metodo_pago/",
      method: "GET",
    });
  },
  getBancos: () => {
    return axios.request({
      url: "/api/bancos/",
      method: "GET",
    });
  },
  getSocioGeneros: () => {
    return axios.request({
      url: "/api/genero_socio/",
      method: "GET",
    });
  },
  getSocioNutricion: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/nutricion/`,
      method: "GET",
    });
  },
  getSocioArchivoAdjuntos: (socioid: number) => {
    return axios.request({
      url: `/api/socios/${socioid}/adjuntos/`,
      method: "GET",
    });
  },
  addSocioPago: (
    socioid: number | undefined,
    validatedFormData: SocioPagosFormInput
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/agregar_pago/`,
      method: "PATCH",
      data: validatedFormData,
    });
  },
  addSocioArchivoAdjunto: (
    socioid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    form_data.append("archivo", validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/socios/${socioid}/adjuntos/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  deleteSocioArchivoAdjuntos: (
    socioid: number | undefined,
    adjuntoid: number
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/adjuntos/`,
      method: "DELETE",
      data: {
        id: adjuntoid,
      },
    });
  },
  getSociosDisponiblesGrupo: (grupoid: number | undefined) => {
    return axios.request({
      url: `api/socios/?filtrar_grupo=${grupoid}`,
      method: "GET",
      transformResponse: [
        function (data) {
          return SociosTransformer(data);
        },
      ],
    });
  },
  getSocioMetodosPago: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "GET",
    });
  },
  addSocioMetodosPago: (
    socioid: number | undefined,
    data: SocioMetodoPagoFormInput
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "POST",
      data: data,
    });
  },
  deleteSocioMetodosPago: (
    socioid: number | undefined,
    metodopagoid: number
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "DELETE",
      data: {
        id: metodopagoid,
      },
    });
  },
};
