import React from "react";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import { useTranslation } from "react-i18next";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { withTheme } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import VolatileStore from "../../stores/VolatileStore";

const StyledTextField = withTheme(styled(TextField)`
  background-color: ${(props) => props.theme.palette.secondary.light};
  width: 100%;
  cursor: pointer;
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

const StyledStrong = styled("strong")`
  width: 100%;
`;
const StyledDiv = styled("div")`
  margin-left: 10px;
  width: 60%;
`;
type IProps = {
  initialValue: any;
  id: string;
  type: "text" | "date";
  patchFunction?: (id: string, value: any) => any;
  reload?: boolean;
  needs_group?: string;
};

const ToggleInput: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const volatileStore = useStore(VolatileStore);
  const [editable, setEditable] = React.useState(false);
  const [value, setValue] = React.useState(props.initialValue);
  const { t } = useTranslation();

  const handleClick = (e: any) => {
    navigator.clipboard.writeText(e.target.innerHTML);
  };
  const handleDoubleClick = (e: any) => {
    if (
      (props.needs_group &&
        volatileStore.user?.tiene_permiso(props.needs_group)) ||
      !props.needs_group
    ) {
      setEditable(true);
    }
  };
  const handleBlur = async (e: any) => {
    if (value !== props.initialValue) {
      let result: any;
      if (props.patchFunction) {
        result = await props.patchFunction(props.id, value);
      } else {
        result = await rootStore.currentSocio?.patchSocio(props.id, value);
      }
      if (!result) {
        setValue(props.initialValue);
      }
    }
    if (props.reload) {
      rootStore.refreshCurrentSocio().then(() => {
        setEditable(false);
      });
    } else {
      setEditable(false);
    }
  };
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <StyledDiv>
      <ReactTooltip />
      {editable && (
        <StyledTextField
          id={props.id}
          type={props.type}
          autoFocus
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        />
      )}
      {!editable && (
        <StyledStrong
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          data-tip={t("Hace doble click para editar el campo.")}
        >
          {value}
        </StyledStrong>
      )}
    </StyledDiv>
  );
};
export default observer(ToggleInput);
