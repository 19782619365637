import { PagoSocio } from "../models/PagoSocio";

export const PagoSocioTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}
  if (data !== undefined) {
    return PagoSocio.create({
      id: data.id,
      monto: parseFloat(data.monto),
      metodo: data.tipo_metodo_pago.nombre,
      fecha: data.fecha_pago,
    });
  }
};
