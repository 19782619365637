import { ActividadSocioTransformer } from "./ActividadSocioTransformer";

export const ActividadesSocioTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}
  let actividades: any[] = [];
  if (data !== undefined) {
    for (let actividad of data) {
      actividades.push(ActividadSocioTransformer(actividad));
    }
  }
  return actividades;
};
