import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import VolatileStore from "../../stores/VolatileStore";
import styled from "styled-components";
import MUIDataTable, { FilterType } from "mui-datatables";
import { Drawer, Typography, withTheme } from "@material-ui/core";
import AppConfig from "../../config";
import CustomToolbar from "../DataGrid/CustomToolbar";
import { api } from "../../api";
import LoadingDot from "../LoadingDot/LoadingDot";
import ObsNutricionCreateForm from "../ObsNutricionCreateForm/ObsNutricionCreateForm";
import ObsNutricionEditForm from "../ObsNutricionEditForm/ObsNutricionEditForm";

const SocioProfilePanelNutricionWrapper = styled.div``;
const DrawerWrapper = withTheme(styled(Drawer)`
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.primary.main};
    width: 20%;
    padding: 1%;
  }
`);
const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
    },
  },
  {
    name: "fecha",
    label: "Fecha",
  },
];

type IProps = {};

const SocioProfilePanelNutricion: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const volatileStore = useStore(VolatileStore);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<any | null>([]);
  const [loading, setLoading] = React.useState(true);
  const [openEdit, setopenEdit] = React.useState(false);
  const [obsData, setobsData] = React.useState("");
  const create = () => {
    if (volatileStore.user?.tiene_permiso(AppConfig.PERMISO_NUTRICION)) {
      setOpen(true);
    }
  };
  const options = {
    filterType: "textField" as FilterType,
    expandableRowsOnClick: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      if (volatileStore.user?.tiene_permiso(AppConfig.PERMISO_NUTRICION)) {
        setopenEdit(true);
        data.map((value: any) => {
          if (value.id === data[rowMeta.rowIndex].id) {
            setobsData(value);
          }
        });
      }
    },
    textLabels: AppConfig.DATATABLE_LABELS,
    customToolbar: () => {
      return <CustomToolbar handleClickCreate={create} />;
    },
  };

  React.useEffect(() => {
    async function fetchData() {
      const result = await api.getObsNutricion(rootStore.currentSocio?.id);
      setData(result.data);
      setLoading(false);
    }
    fetchData();
  }, [open, openEdit]);

  if (loading) {
    return (
      <SocioProfilePanelNutricionWrapper data-testid="SocioProfilePanelNutricion">
        <LoadingDot height={"calc(200px)"} />;
      </SocioProfilePanelNutricionWrapper>
    );
  }

  return (
    <>
      <SocioProfilePanelNutricionWrapper data-testid="SocioProfilePanelNutricion">
        <MUIDataTable
          title={t("Observaciones Nutricion")}
          data={data ? data : []}
          columns={columns}
          options={options}
        />
      </SocioProfilePanelNutricionWrapper>
      <DrawerWrapper anchor="left" open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5" align="center">
          {t("Agregar Observacion Nutricional")}
        </Typography>
        <ObsNutricionCreateForm closeback={setOpen} />
      </DrawerWrapper>
      <DrawerWrapper
        anchor="left"
        open={openEdit}
        onClose={() => setopenEdit(false)}
      >
        <Typography variant="h5" align="center">
          {t("Editar Observacion Nutricional")}
        </Typography>
        <ObsNutricionEditForm closeback={setOpen} data={obsData} />
      </DrawerWrapper>
    </>
  );
};
export default observer(SocioProfilePanelNutricion);
