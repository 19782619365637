import { types, flow } from "mobx-state-tree";
import { User } from "../models/User";
import { api } from "../api";
import { LoginFormInput } from "../types";

const VolatileStore = types
  .model({
    user: types.maybe(User),
    isAuthenticated: false,
  })
  .actions((self) => {
    return {
      logIn: flow(function* (formData: LoginFormInput) {
        try {
          const AuthResponse = yield api.login(formData);
          if (AuthResponse.status === 200) {
            self.isAuthenticated = true;
            self.user = AuthResponse.data;
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
          return false;
        }
      }),
      logOut: flow(function* () {
        const AuthResponse = yield api.logout();
        self.isAuthenticated = false;
        self.user = undefined;
        return AuthResponse;
      }),
      verify: flow(function* () {
        const AuthResponse = yield api.verify();
        if (AuthResponse && AuthResponse.status === 200) {
          self.isAuthenticated = true;
          self.user = AuthResponse.data;
        } else {
          self.isAuthenticated = false;
          self.user = undefined;
        }
      }),
    };
  });
export default VolatileStore;
