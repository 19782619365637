import React from "react";
import { useTranslation } from "react-i18next";
import FullWidthPage from "../FullWidthPage/FullWidthPage";
import styled from "styled-components";
import {
  Breadcrumbs,
  Container,
  Typography,
  useTheme,
} from "@material-ui/core";
import SocioList from "../../components/SocioList/SocioList";
import { Link } from "@reach/router";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const SocioListContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  overflow: hidden;
  color: #fff;
`;
type IProps = {
  path?: string;
};

const SocioListPage: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <FullWidthPage
      testid="SocioListPage"
      backgroundColor={theme.palette.secondary.dark}
    >
      <SocioListContainer maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <HomeIcon />
          </Link>
          <Typography color="textPrimary">
            <PeopleAltIcon />
            {t("Socios")}
          </Typography>
        </Breadcrumbs>
        <SocioList />
      </SocioListContainer>
    </FullWidthPage>
  );
};

export default SocioListPage;
