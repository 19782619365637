import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import LoadingDot from "../LoadingDot/LoadingDot";
import {
  AccordionActions,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextareaAutosize,
  withTheme,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../api";
import { SocioMetodoPagoFormInput } from "../../types";
import { addServerErrors } from "../../utils/storage";

const SocioProfilePanelMetodosPagoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Roboto";
  font-weight: 300;
`;

const StyledForm = styled("form")`
  display: flex;
  justify-content: center;
`;
const StyledFormControl = styled(FormControl)`
  width: 80%;
`;
const StyledTextArea = styled(TextareaAutosize)`
  width: 80%;
`;
const StyledAccordion = withTheme(styled(Accordion)`
  && {
    width: 80%;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`);

type IProps = {};

const SocioProfilePanelMetodosPago: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [showForm, setShowForm] = React.useState(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SocioMetodoPagoFormInput>();

  React.useEffect(() => {
    rootStore.currentSocio?.getSocioMetodosPago().then(() => {
      setLoading(false);
    });
  }, []);

  const onSubmit = async (data: SocioMetodoPagoFormInput) => {
    setLoading(true);
    const result = await api.addSocioMetodosPago(
      rootStore.currentSocio!.id,
      data
    );
    if (result) {
      if (result.status === 200) {
        rootStore.currentSocio?.getSocioMetodosPago().then(() => {
          setLoading(false);
          setShowForm(false);
        });
      } else {
        addServerErrors(result.data, setError);
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <LoadingDot height={"calc(100vh - 124px)"} />;
  }

  const handleClick = (e: any) => {
    setLoading(true);
    rootStore.getBancos().then(() => {
      rootStore.getSocioTipoMetodoPagos().then(() => {
        setLoading(false);
        setShowForm(true);
      });
    });
  };

  const handleBorrar = (socio_metodo_pago_id: number) => {
    setLoading(true);
    rootStore.currentSocio
      ?.deleteSocioMetodosPago(socio_metodo_pago_id)
      .then(() => {
        rootStore.currentSocio?.getSocioMetodosPago().then(() => {
          setLoading(false);
        });
      });
  };

  return (
    <SocioProfilePanelMetodosPagoWrapper data-testid="SocioProfilePanelMetodosPago">
      {rootStore.currentSocio?.metodos_pago.map((key) => {
        return (
          <StyledAccordion key={key.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {key.tipo_metodo_pago?.nombre + " " + key.banco?.nombre}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{key.datos}</Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" onClick={() => handleBorrar(key.id)}>
                Borrar
              </Button>
            </AccordionActions>
          </StyledAccordion>
        );
      })}
      <List>
        <ListItem>
          <Button onClick={handleClick}>
            <AddCircleIcon />
          </Button>
        </ListItem>
      </List>
      {showForm && (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <StyledFormControl>
                    <InputLabel id="tipo_metodopago_id">
                      {t("Método de Pago")}
                    </InputLabel>
                    <Select
                      id="tipo_metodopago_id"
                      error={errors.tipo_metodopago_id !== undefined}
                      {...field}
                    >
                      {rootStore.tipometodopagos.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.tipo_metodopago_id?.message}
                    </FormHelperText>
                  </StyledFormControl>
                )}
                name="tipo_metodopago_id"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <StyledFormControl>
                    <InputLabel id="banco_id">{t("Banco")}</InputLabel>
                    <Select
                      id="banco_id"
                      error={errors.banco_id !== undefined}
                      {...field}
                    >
                      {rootStore.bancos.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.banco_id?.message}</FormHelperText>
                  </StyledFormControl>
                )}
                name="banco_id"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <StyledTextArea
                    id="datos"
                    rowsMin={5}
                    placeholder={t("Datos adicionales")}
                    {...field}
                  />
                )}
                name="datos"
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Agregar
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      )}
    </SocioProfilePanelMetodosPagoWrapper>
  );
};
export default observer(SocioProfilePanelMetodosPago);
