import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../api";
import { addServerErrors } from "../../utils/storage";
import { SocioGrupoFormInput } from "../../types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    withTheme,
} from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";
import { optional } from "mobx-state-tree/dist/internal";

const SocioGrupoFormWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;
const StyledFormControl = withTheme(styled(FormControl)`
  width: 250px;
  justify-items: center;
`);
const StyledTextField = withTheme(styled(TextField)`
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

const StyledForm = withTheme(styled("form")`
  width: 100%;
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  }
  & .MuiGrid-item {
    display: flex;
    justify-content: center;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
const StyledMenuItem = withTheme(styled(MenuItem)`
  && {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
type IProps = {
    closeback: (open: boolean) => any;
};

const SocioGrupoForm: React.FC<IProps> = (props) => {
    const rootStore = useStore(RootStore);
    const [loading, setLoading] = React.useState(true);
    const [selectedSocio, setSelectedSocio] = React.useState(null);
    const [formError, setFormError] = React.useState(false);
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<SocioGrupoFormInput>();

    const onSubmit = async (data: SocioGrupoFormInput) => {

        const result = await api.patchSocio(data.socio_id, 'grupo_socio', rootStore.currentSocio?.grupo.id);
        if (result) {
            if (result.status === 200) {
                setLoading(true);
                rootStore.refreshCurrentSocio().then(() => {
                    props.closeback(false);
                });
            } else {
                addServerErrors(result.data, setError);
            }
        }
    };

    const handleAgregar = async () => {
        if (selectedSocio !== null) {
            var aux: number = +selectedSocio!;
            const result = await api.patchSocio(aux, 'grupo_socio', rootStore.currentSocio?.grupo.id);
            if (result) {
                if (result.status === 200) {
                    setLoading(true);
                    rootStore.refreshCurrentSocio().then(() => {
                        props.closeback(false);
                    });
                } else {
                    addServerErrors(result.data, setError);
                }
            }
        }else{
            setFormError(true);
        }
    }

    const handleChange = (event: any, value: any) => {
        setSelectedSocio(value.id);
        if (value.id !== null)
            setFormError(false);
    }

    React.useEffect(() => {
        rootStore.getSociosDisponiblesGrupo(rootStore.currentSocio?.grupo.id).then(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <LoadingDot height={"350px"} />;
    }
    return (
        <SocioGrupoFormWrapper data-testid="SocioActividadForm">
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Controller
                            render={() => (
                                <StyledFormControl>
                                    <Autocomplete
                                        id="socio_id"
                                        options={rootStore.sociosDisponiblesGrupo}
                                        onChange={handleChange}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ color: "black" }}>{children}</Paper>
                                        )}
                                        getOptionLabel={(option) => option.nombre + " " + option.apellido}
                                        renderInput={(params) => <TextField helperText={formError ? "Debe seleccionar 1 socio" : ''}
                                             {...params} label="Socio" variant="outlined" />}
                                    />
                                </StyledFormControl>
                            )}
                            name="socio_id"
                            control={control}
                            rules={{
                                required: true,
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={handleAgregar}>
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </StyledForm>
        </SocioGrupoFormWrapper>
    );
};
export default observer(SocioGrupoForm);
