import { createMuiTheme } from "@material-ui/core";

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=f22233&secondary.color=173f73
// colores para el theme sacados de la página elfronton.club

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff635d",
      main: "#f22233",
      dark: "#b7000c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4c69a2",
      main: "#173f73",
      dark: "#001a47",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiBreadcrumbs: {
      // Name of the rule
      ol: {
        "& a": {
          textDecoration: "none",
          color: "#f22233",
          display: "flex",
        },
        "& .MuiSvgIcon-root": {
          color: "#f22233",
          width: "24px",
          height: "24px",
          marginRight: "5px",
        },
        "& .MuiTypography-root": {
          display: "flex",
          height: "24px",
        },
      },
      root: {
        paddingTop: "10px",
        paddingBottom: "10px",
        // Some CSS
      },
    },
  },
});

theme.overrides = {
  ...theme.overrides,
  //color toolbar + titulo + sombra tabla
  MUIDataTable: {
    paper: {
      width: "100%",
      boxShadow: "none",
      "& .MuiToolbar-root": {
        backgroundColor: theme.palette.secondary.dark /*+ "!important"*/,
      },
      "& .MuiPaper-root": {
        backgroundColor: theme.palette.secondary.dark /* + "!important"*/,
      },
    },
    caption: {
      color: theme.palette.secondary.contrastText,
    },
  },
  
  /*
  MuiMenuItem: {
    root: {
      color: theme.palette.secondary.dark,
    },
  },
  */
  //color flechita footer
  MuiSelect: {
    icon: {
      color: theme.palette.secondary.contrastText,
    },
    selectMenu: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //ajuste en la lupita
  MUIDataTableFilterList: {
    root: {
      backgroundColor: theme.palette.secondary.dark + "!important",
      paddingLeft: "16px",
      paddingRight: "16px",
      margin: "0px",
    },
  },
  //mostrar/esconder columnas
  MUIDataTableViewCol: {
    root: {
      backgroundColor: theme.palette.secondary.dark,
    },
    label: {
      color: theme.palette.secondary.contrastText,
    },
    title: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //campo de busqueda
  MUIDataTableSearch: {
    searchIcon: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //filtros
  MUIDataTableFilter: {
    root: {
      backgroundColor: theme.palette.secondary.dark,
    },
    title: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //campos de texto
  MuiInput: {
    root: {
      "&.Mui-focused": {
        color: theme.palette.secondary.contrastText,
      },
      color: theme.palette.secondary.contrastText,
    },
  },
  //labels
  MuiFormLabel: {
    root: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //cuando seleccionas todo con el select all
  MuiTableBody: {
    root: {
      backgroundColor: theme.palette.secondary.light
    }
  },
  
  // handles data row color; clashes and blocks MuiTableRow
  /*MUIDataTableBodyCell: {
    root: {
      '&$hover:hover': {
        backgroundColor: '#001a47',
      },
      '&$selected': {
        backgroundColor: '#001a47'
      },
      '&$checked': {
        backgroundColor: '#001a47'
      },
    }
  },*/

  MuiTableCell: {
    //header
    head: {
      backgroundColor: theme.palette.secondary.dark + "!important",
    },
    /*
    footer: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light + '!important'
      }
    }
    */
    /*
    body: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.dark,
    },
    */
  },

  /*MUIDataTableSelectCell: {
    checked: { color: "#fff" },
    checkboxRoot: { color: "#fff" },
    root: {
      backgroundColor: '#001a47',
      color: '#fff',
    },
  },*/
  /*
  MuiTableFooter: {
    root: {
      '& .MuiToolbar-root': {
        backgroundColor: '#001a47',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light + '!important'
        }
      },
      color: '#fff',
      '&:hover': {
        background: theme.palette.secondary.light + '!important'
      }
    },
  },
  */
  // handles row hover color and selected row color
  MuiTableRow: {
    root: {
      border: '2px solid ' + theme.palette.secondary.contrastText + ' !important',
      cursor: "pointer",
      /*
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        fontWeight: 800,
        backgroundColor: theme.palette.secondary.light + '!important'
      },
      */
      /*
      '& .MuiToolbar-root': {
        backgroundColor: '#001a47',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light + '!important'
        }
      },
      */
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.secondary.dark
      },
      '&:nth-child(even)': {
        backgroundColor: theme.palette.primary.light
      }
    },
  },
  //lista de filas por pagina
  MuiList: {
    root: {
      color: theme.palette.secondary.dark,
    }
  },
  // handles table data header color
  MUIDataTableHeadCell: {
    sortAction: {
      "& path": {
        color: theme.palette.secondary.contrastText,
      },
    },
    sortActive: {
      color: theme.palette.secondary.contrastText,
    },
    data: {
      color: theme.palette.secondary.contrastText,
    },
  },
  // handles table title bar color
  /*
  MUIDataTableToolbar: {
    icon: {
      //color: "#fff",
      '&:hover': {
        backgroundColor: theme.palette.secondary.light + '!important'
      }
    },
  },
  */
  /*
  MuiTablePagination: {
    selectIcon: {
      color: theme.palette.secondary.contrastText,
    },
    selectRoot: {
      color: theme.palette.secondary.contrastText,
    },
    root: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light + '!important'
      }
    }
  },
  */
  //color iconos toolbar
  MuiIconButton: {
    root: {
      color: theme.palette.secondary.contrastText + "!important",
    },
  },
  // handles data footer color
  /*
  MUIDataTablePagination: {
    root: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light + '!important'
      }
    }
  },
  */
  MuiTableFooter: {
    root: {
      background: "yellow !important",
      '&:hover': {
        backgroundColor: "green !important"
      }
    }
  },
};
export default theme;
