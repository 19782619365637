import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Avatar, Card, Grid, Typography, withTheme, Drawer } from "@material-ui/core";
import { navigate } from "@reach/router";
import AppConfig from "../../config";
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SocioGrupoForm from "../SocioGrupoForm/SocioGrupoForm";

const SocioProfileGrupoWrapper = styled.div`
  display: flex;
  flex-orientation: column;
  width: 100%;
  padding-top: 20px;
`;
const StyledCard = withTheme(styled(Card)`
  width: 100%;
  color: ${(props) => props.theme.palette.secondary.dark} !important;
  font-family: "Roboto";
  padding: 20px;
  font-size: 0.9rem;
  font-weight: 200;
  & .MuiTypography-root {
    padding-bottom: 20px;
  }
  & .MuiListItem-root {
    justify-content: flex-end;
  }
  & .MuiListItem-root a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.secondary.light};
    height: 24px;
    line-height: 24px;
    display: flex;
    width: 60%;
    padding-left: 10px;
  }
  & .MuiListItem-root strong {
    width: 60%;
    padding-left: 10px;
  }
`);
const DrawerWrapper = withTheme(styled(Drawer)`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 20%;
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.primary.main};
    padding: 1%;
  }
`);

type StyledGridItemProps = {
  $titular: boolean;
};

const StyledGridItem = withTheme(styled(Grid) <StyledGridItemProps>`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.dark};
  }
  & .MuiTypography-body2 {
    font-weight: ${(props) => (props.$titular ? 800 : 400)};
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

type IProps = {};

const SocioProfileGrupo: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const create = () => {
    setOpen(true);
  };
  return (
    <>
      <SocioProfileGrupoWrapper data-testid="SocioProfileGrupo">
        <StyledCard>
          <Typography variant="h5">{t("Grupo Familiar")}</Typography>
          <Grid container spacing={4}>
            {rootStore.currentSocio?.grupo.miembros.map((miembro: any) => {
              return (
                <StyledGridItem
                  key={miembro.id}
                  item
                  $titular={rootStore.currentSocio?.grupo.titular === miembro.id}
                  onClick={() => navigate(`/socio/${miembro.id}`)}
                >
                  {rootStore.currentSocio?.grupo.titular === miembro.id ?
                    <Tooltip title="Lider de Grupo">
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={<StarIcon stroke={"black"} stroke-width={1} style={{ fill: 'yellow' }} />}
                      >
                        <Avatar
                          src={AppConfig.API_BASE_URL + miembro.foto}
                          alt={miembro.nombre}
                          className={classes.large}
                        />
                      </Badge>
                    </Tooltip>
                    :
                    <Avatar
                      src={AppConfig.API_BASE_URL + miembro.foto}
                      alt={miembro.nombre}
                      className={classes.large}
                    />
                  }
                  <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                    <Box fontWeight='fontWeightBold' fontSize={22} display='inline'>{miembro.nombre}</Box> <br></br> <Box justifyContent="center" display='flex'> {miembro.apellido} </Box>
                  </Typography>
                </StyledGridItem>
              );
            })}
            <StyledGridItem
              item
              onClick={() => setOpen(true)}
            >
              <Avatar
                className={classes.large}
              >
                <AddCircleIcon fontSize='large' />
              </Avatar>
              <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                <Box justifyContent="center" fontSize={22} display='inline'>Agregar</Box> <br></br> <Box justifyContent="center" display='flex'> Miembro </Box>
              </Typography>
            </StyledGridItem>
          </Grid>
        </StyledCard>
      </SocioProfileGrupoWrapper>
      <DrawerWrapper anchor="left" open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5" align="center">
          {t("Agregar miembro")}
        </Typography>
        <SocioGrupoForm closeback={setOpen} />
      </DrawerWrapper>
    </>
  );
};
export default observer(SocioProfileGrupo);
