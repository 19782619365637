import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { SocioActividadFormInput } from "../../types";
import { api } from "../../api";
import { addServerErrors } from "../../utils/storage";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  withTheme,
} from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";

const SocioActividadFormWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;
const StyledFormControl = styled(FormControl)`
  width: 250px;
  justify-items: center;
`;
const StyledForm = withTheme(styled("form")`
  width: 100%;
  text-align: center;
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  }
  & .MuiGrid-item {
    display: flex;
    justify-content: center;
  }
`);
const StyledMenuItem = withTheme(styled(MenuItem)`
  && {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
type IProps = {
  closeback: (open: boolean) => any;
};

const SocioActividadForm: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SocioActividadFormInput>();
  const onSubmit = async (data: SocioActividadFormInput) => {
    const result = await api.addSocioActividades(
      rootStore.currentSocio?.id,
      data
    );
    if (result) {
      if (result.status === 200) {
        setLoading(true);
        rootStore.refreshCurrentSocio().then(() => {
          props.closeback(false);
        });
      } else {
        addServerErrors(result.data, setError);
      }
    }
  };
  React.useEffect(() => {
    rootStore.getSocioActividades().then(() => {
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <LoadingDot height={"350px"} />;
  }
  return (
    <SocioActividadFormWrapper data-testid="SocioActividadForm">
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <StyledFormControl>
                  <InputLabel id="id">{t("Actividad")}</InputLabel>
                  <Select
                    id="genero"
                    error={errors.id !== undefined}
                    {...field}
                  >
                    {rootStore.actividades.map((option) => (
                      <StyledMenuItem value={option.id} key={option.id}>
                        {option.nombre}
                      </StyledMenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.id?.message}</FormHelperText>
                </StyledFormControl>
              )}
              name="id"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Agregar
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </SocioActividadFormWrapper>
  );
};
export default observer(SocioActividadForm);
