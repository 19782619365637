import { types } from "mobx-state-tree";

export const User = types
  .model("User", {
    username: "",
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    avatarImage: "",
    language: "ES",
    permisos: "",
  })
  .actions((self) => {
    return {
      tiene_permiso: (permiso: string) => {
        if (self.permisos.indexOf(permiso) >= 0) return true;
        else return false;
      },
    };
  });
