import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Card3DWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

interface IStyledCardStyleProps {
  backgroundColor?: string;
  width: string;
  image: string;
}

const StyledCard = styled(animated.div)<IStyledCardStyleProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  background-color: ${(props) =>
    props.backgroundColor !== "" ? props.backgroundColor : "transparent"};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  will-change: transform;
`;

type IProps = {
  image: string;
  width: string;
  backgroundColor?: string;
};
type Dimensions = {
  width: number | undefined;
  height: number | undefined;
};
const calc = (x: number, y: number, containerDimensions: Dimensions) => [
  -(y - (containerDimensions.height ? containerDimensions.height : 600) / 2) /
    20,
  (x - (containerDimensions.width ? containerDimensions.width : 600) / 2) / 20,
  1.1,
  containerDimensions.width ? containerDimensions.width : 600,
];

const trans = (x: number, y: number, s: number, w: number) =>
  `perspective(${w}px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Card3D: React.FC<IProps> = (p) => {
  const [props, set] = useSpring(() => ({
    xysw: [0, 0, 1, 600],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setDimensions({
      width: targetRef.current?.offsetWidth,
      height: targetRef.current?.offsetHeight,
    });
  }, []);

  return (
    <Card3DWrapper data-testid="Card3D" ref={targetRef}>
      <StyledCard
        image={p.image}
        width={p.width}
        onMouseMove={({ clientX: x, clientY: y }) =>
          set({ xysw: calc(x, y, dimensions) })
        }
        onMouseLeave={() =>
          set({
            xysw: [0, 0, 1, dimensions.width ? dimensions.width : 600],
          })
        }
        style={{ transform: props.xysw.interpolate(trans) }}
      />
    </Card3DWrapper>
  );
};
export default observer(Card3D);
