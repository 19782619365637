import { Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AppConfig from "../../config";
import Hidden from "@material-ui/core/Hidden";
import Card3D from "../Card3D/Card3D";

const HeaderLogoWrapper = styled.div`
  display: flex;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledTypography = styled(Typography)`
  && {
    line-height: 64px;
  }
`;
type IProps = {};

const HeaderLogo: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  return (
    <HeaderLogoWrapper data-testid="HeaderLogo">
      <StyledLink to="/">
        <Card3D image={AppConfig.APP_LOGO_URL} width={"60px"} />
      </StyledLink>
      <Hidden smDown>
        <StyledLink to="/">
          <StyledTypography noWrap variant="h6">
            {t("Club Manager")}
          </StyledTypography>
        </StyledLink>
      </Hidden>
    </HeaderLogoWrapper>
  );
};
export default HeaderLogo;
