const AppConfig = {
  API_BASE_URL: "https://clubmanager.lesmenes.com",
  STORE_NAME: "clubmanagerstore",
  APP_NAME: "El Frontón",
  APP_LOGO_URL:
    "https://elfronton.club/wp-content/uploads/2019/09/el-fronton-logo.png",
  DATATABLE_LABELS: {
    pagination: {
      next: "Proxima",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de", // 1-10 of 30
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Mostrar Columnas",
      filterTable: "Filtrar Tabla",
    },
    filter: {
      title: "FILTROS",
      reset: "reset",
    },
    viewColumns: {
      title: "Mostrar Columnas",
    },
    selectedRows: {
      text: "fila(s) seleccionadas",
      delete: "Borrar",
    },
    body: {
      noMatch: "No se encontraron registros",
    },
  } as any,
  PERMISO_PSICOLOGIA: "psicologia",
  PERMISO_NUTRICION: "nutricion",
};

export default AppConfig;
