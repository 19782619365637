import { Socio } from "../models/Socio";
import { Grupo } from "../models/Grupo";
import { ActividadesSocioTransformer } from "./ActividadesSocioTransformer";
import { PagosSocioTransformer } from "./PagosTransformer";

export const SocioTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}
  let miembros_grupo: any[] = [];
  let actividades_miembro: any[] = [];
  let pagos: any[] = [];
  let grupo = undefined;
  if (data !== undefined) {
    if (data.grupo_socio) {
      for (let miembro of data.grupo_socio.miembros) {
        if (miembro.id !== data.id) {
          let membrillo = Socio.create({
            id: miembro.id,
            nombre: miembro.nombre,
            apellido: miembro.apellido,
            foto: miembro.foto,
          });
          miembros_grupo.push(membrillo);
        }
      }
      grupo = Grupo.create({
        id: data.grupo_socio.id,
        titular: data.grupo_socio.titular.id,
        miembros: miembros_grupo,
      });
    }
    if (data.actividades) {
      actividades_miembro = ActividadesSocioTransformer(data.actividades);
    }
    if (data.pagos) {
      pagos = PagosSocioTransformer(data.pagos);
    }
    return Socio.create({
      id: data.id,
      nombre: data.nombre,
      apellido: data.apellido,
      dni: data.dni,
      numero: data.numero,
      direccion: data.direccion,
      email: data.email,
      telefono_linea: data.telefono_linea,
      telefono_celular: data.telefono_celular,
      fecha_nacimiento: data.fecha_nacimiento,
      psicologiasocio: data.psicologiasocio.id,
      nutricionsocio: data.nutricionsocio.id,
      codigo_postal: data.codigo_postal,
      pais: data.pais ? data.pais.nombre : "",
      provincia: data.provincia ? data.provincia.nombre : "",
      localidad: data.localidad ? data.localidad.nombre : "",
      genero: data.genero ? data.genero.nombre : "",
      foto: data.foto,
      tipo_socio: data.tipo_socio ? data.tipo_socio.nombre : "",
      fecha_inscripcion: data.fecha_inscripcion,
      grupo: grupo ? grupo : undefined,
      actividades: actividades_miembro ? actividades_miembro : [],
      pagos: pagos ? pagos : [],
    });
  } else {
    return false;
  }
};
