import React from "react";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import { useTranslation } from "react-i18next";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { withTheme } from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";
import ReactTooltip from "react-tooltip";

const StyledTextField = withTheme(styled(TextField)`
  background-color: ${(props) => props.theme.palette.secondary.light};
  width: 100%;
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

const StyledSelect = withTheme(styled(Select)`
  color: ${(props) => props.theme.palette.secondary.dark};
`);

const StyledStrong = styled("strong")`
  width: 100%;
`;
const StyledDiv = styled("div")`
  margin-left: 10px;
  width: 60%;
`;
type IProps = {
  initialValue: any;
  id: string;
  getOptions: () => any;
  patchFunction?: (id: string, value: any) => any;
};

const ToggleInput: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const [editable, setEditable] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(props.initialValue);
  const [label, setLabel] = React.useState(props.initialValue);
  const { t } = useTranslation();

  const handleDoubleClick = (e: any) => {
    setEditable(true);
  };
  const handleBlur = async (e: any) => {
    if (value !== props.initialValue) {
      let result: any;
      if (props.patchFunction) {
        result = await props.patchFunction(props.id, value);
      } else {
        result = await rootStore.currentSocio?.patchSocio(props.id, value);
      }
      setLabel(getOptionName(value));
      console.log(result);
      if (!result) {
        setValue(props.initialValue);
      }
    }
    setEditable(false);
  };
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  const getOptionName = (id: number) => {
    const options = rootStore.getOption(props.id);
    for (let option of options) {
      if (option.id === id) return option.nombre;
    }
  };
  const getOptionID = (nombre: string) => {
    const options = rootStore.getOption(props.id);
    for (let option of options) {
      if (option.nombre === nombre) return option.id;
    }
  };

  React.useEffect(() => {
    props.getOptions().then(() => {
      setValue(getOptionID(props.initialValue));
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingDot height={"30px"} />;
  }

  return (
    <StyledDiv>
      <ReactTooltip />
      {editable && (
        <StyledSelect
          id={props.id}
          autoFocus
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        >
          {rootStore.getOption(props.id).map((option: any) => {
            return (
              <MenuItem value={option.id} key={option.id}>
                {option.nombre}
              </MenuItem>
            );
          })}
        </StyledSelect>
      )}
      {!editable && (
        <StyledStrong
          onDoubleClick={handleDoubleClick}
          data-tip={t("Hace doble click para editar el campo.")}
        >
          {label}
        </StyledStrong>
      )}
    </StyledDiv>
  );
};
export default observer(ToggleInput);
