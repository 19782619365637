import { PagoSocioTransformer } from "./PagoTransformer";

export const PagosSocioTransformer = (data: any) => {
  try {
    data = JSON.parse(data);
  } catch (e) {}
  let pagos: any[] = [];
  if (data !== undefined) {
    for (let actividad of data) {
      pagos.push(PagoSocioTransformer(actividad));
    }
  }
  return pagos;
};
