import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";

const SocioActividadWrapper = styled.div`
  display: flex;
`;

type IProps = {};

const SocioActividad: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();

  return (
    <SocioActividadWrapper data-testid="SocioActividad"></SocioActividadWrapper>
  );
};
export default observer(SocioActividad);
