import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { SocioPagosFormInput } from "../../types";
import { api } from "../../api";
import { addServerErrors } from "../../utils/storage";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withTheme,
} from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";

const SocioPagosFormWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;
const StyledFormControl = styled(FormControl)`
  width: 250px;
  justify-items: center;
`;
const StyledTextField = withTheme(styled(TextField)`
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

const StyledForm = withTheme(styled("form")`
  width: 100%;
  text-align: center;
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  }
  & .MuiGrid-item {
    display: flex;
    justify-content: center;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
const StyledMenuItem = withTheme(styled(MenuItem)`
  && {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`);
type IProps = {
  closeback: (open: boolean) => any;
};

const SocioPagosForm: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SocioPagosFormInput>();
  const onSubmit = async (data: SocioPagosFormInput) => {
    const result = await api.addSocioPago(rootStore.currentSocio?.id, data);
    if (result) {
      if (result.status === 200) {
        setLoading(true);
        rootStore.refreshCurrentSocio().then(() => {
          props.closeback(false);
        });
      } else {
        addServerErrors(result.data, setError);
      }
    }
  };

  React.useEffect(() => {
    rootStore.getSocioTipoMetodoPagos().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingDot height={"350px"} />;
  }
  return (
    <SocioPagosFormWrapper data-testid="SocioActividadForm">
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <StyledFormControl>
                  <InputLabel id="tipo_metodopago_id">
                    {t("Método de Pago")}
                  </InputLabel>
                  <Select
                    id="tipo_metodopago_id"
                    error={errors.tipo_metodopago_id !== undefined}
                    {...field}
                  >
                    {rootStore.tipometodopagos.map((option) => (
                      <StyledMenuItem value={option.id} key={option.id}>
                        {option.nombre}
                      </StyledMenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.tipo_metodopago_id?.message}
                  </FormHelperText>
                </StyledFormControl>
              )}
              name="tipo_metodopago_id"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="monto"
                  label={t("Monto")}
                  error={errors.monto !== undefined}
                  helperText={errors.monto?.message}
                  {...field}
                />
              )}
              name="monto"
              control={control}
              defaultValue={0}
              rules={{
                pattern: {
                  value: /[0-9]+/,
                  message: t("Por favor ingrese sólo números."),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="fecha_pago"
                  label={t("Fecha Pago")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.fecha_pago !== undefined}
                  helperText={errors.fecha_pago?.message}
                  {...field}
                />
              )}
              name="fecha_pago"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Agregar
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </SocioPagosFormWrapper>
  );
};
export default observer(SocioPagosForm);
