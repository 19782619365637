import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withTheme,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import customData from "../../utils/data.json";
import theme from "../../theme";
import { api } from "../../api";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const DashboardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const FixedHeightDiv = styled("div")`
  height: 350px;
`;

const customTheme = createMuiTheme({
  palette: {
    secondary: {
      main: theme.palette.secondary.main,
      light: theme.palette.secondary.light,
      dark: theme.palette.secondary.contrastText,
    },
    text: {
      primary: theme.palette.text.primary,
      secondary: theme.palette.text.secondary,
    },
  },
});

const StyledList = withTheme(styled(List)`
  && {
    background-color: ${(props) => props.theme.palette.secondary.light};
    margin-top: 40px;
    border-radius: 5px;
  }
`);
const StyledAvatar = withTheme(styled(Avatar)`
  && {
    background-color: ${(props) => props.theme.palette.secondary.dark};
    color: ${(props) => props.theme.palette.secondary.light};
  }
`);

type IProps = {};

const Dashboard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [pieData, setpieData] = React.useState<any | null>([]);
  const [barData, setbarData] = React.useState<any | null>([]);

  React.useEffect(() => {
    async function fetchData() {
      const result = await api.getChartData();
      setpieData(result.data.piechart);
      setbarData(result.data.barchart);
    }
    fetchData();
  }, []);

  return (
    <DashboardWrapper data-testid="Dashboard">
      <ThemeProvider theme={customTheme}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography variant="h5">{t("Inscriptos por edad")}</Typography>
          <FixedHeightDiv>
            <ResponsiveBar
              data={barData}
              keys={["0-15", "15-30", "30-45", "45-60", "60+"]}
              indexBy="edad"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  ticks: {
                    line: {
                      stroke: "#ccc",
                    },
                    text: {
                      fill: "#fff",
                    },
                  },
                  legend: {
                    text: {
                      fill: "#aaaaaa",
                    },
                  },
                },
                tooltip: {
                  basic: {
                    whiteSpace: "pre",
                    display: "flex",
                    alignItems: "center",
                  },
                  container: {
                    background: "white",
                    color: "#000",
                    fontFamily: "Arial",
                    fontSize: "inherit",
                    borderRadius: "2px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
                  },
                },
              }}
              fill={[
                {
                  match: {
                    id: "rugby",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "voley",
                  },
                  id: "lines",
                },
              ]}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 3,
                tickRotation: 0,
                legend: "Rango de Edades",
                legendPosition: "middle",
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Cantidad de Socios",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={theme.palette.text.primary}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 5,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  itemTextColor: "#fff",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
            />
          </FixedHeightDiv>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h5">{t("Ultimas actividades")}</Typography>
          <FixedHeightDiv>
            <StyledList>
              <ListItem>
                <ListItemAvatar>
                  <StyledAvatar>
                    <ImageIcon />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Juan Foyth actualizó su foto de perfil"
                  secondary="10 de Mayo, 2021"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <StyledAvatar>
                    <WorkIcon />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Romina Malaspina firmó su contrato para la división U18 de Hockey sobre Césped"
                  secondary="9 de Mayo, 2021"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <StyledAvatar>
                    <BeachAccessIcon />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Juan Heredia accedió al club"
                  secondary="9 de Mayo, 2021"
                />
              </ListItem>
            </StyledList>
          </FixedHeightDiv>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5">{t("Tipos de socio")}</Typography>
          <FixedHeightDiv>
            <ResponsivePie
              data={pieData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              theme={{
                axis: {
                  ticks: {
                    line: {
                      stroke: "#ccc",
                    },
                    text: {
                      fill: "#fff",
                    },
                  },
                  legend: {
                    text: {
                      fill: "#aaaaaa",
                    },
                  },
                },
                tooltip: {
                  basic: {
                    whiteSpace: "pre",
                    display: "flex",
                    alignItems: "center",
                  },
                  container: {
                    background: "white",
                    color: "#000",
                    fontFamily: "Arial",
                    fontSize: "inherit",
                    borderRadius: "2px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
                  },
                },
              }}
              colors={{ scheme: "red_blue" }}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={theme.palette.primary.contrastText}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
            />
          </FixedHeightDiv>
        </Grid>
      </Grid>
      </ThemeProvider>
    </DashboardWrapper>
  );
};
export default observer(Dashboard);
