import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useStore } from "mobx-store-provider";
import { observer } from "mobx-react";
import VolatileStore from "../../stores/VolatileStore";
import { LoginFormInput } from "../../types";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { navigate } from "@reach/router";
import styled from "styled-components";
import Card3D from "../../components/Card3D/Card3D";
import AppConfig from "../../config";
import { useTheme } from "@material-ui/core";

type IProps = {
  path?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginerror: {
    paddingTop: "0.3em",
    fontSize: "1em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  leftside: {
    background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark}, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    backgroundSize: "200% 200%",
    animationName: "$gradient",
    animationDuration: "5s",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    display: "flex",
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  typog: {
    color: theme.palette.primary.dark,
    paddingBottom: "1rem",
  },
}));

const StyledInput = styled(TextField)`
  & .MuiInputBase-input {
    color: #000;
  }
`;

const LoginPage: React.FC<IProps> = (props) => {
  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://lesmenes.com/">
          LESMENES
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };
  const theme = useTheme();
  const volatileStore = useStore(VolatileStore);
  const classes = useStyles();
  const { t } = useTranslation();
  const [loginError, setLoginError] = React.useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormInput>();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSubmit: SubmitHandler<LoginFormInput> = async (data) => {
    const result = await volatileStore.logIn(data);
    if (result) {
      setLoginError("");
      navigate("/");
    } else {
      setLoginError(t("Usuario o contraseña inválida."));
    }
  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.leftside}>
        <Card3D image={AppConfig.APP_LOGO_URL} width={"45ch"} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.typog}>
            {t("Club Manager")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("El usuario es requerido."),
                },
                minLength: {
                  value: 3,
                  message: t("El usuario debe tener mínimo 3 caracteres"),
                },
              }}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  error={
                    errors?.username?.type === "required" ||
                    errors?.username?.type === "minLength"
                  }
                  helperText={errors.username?.message}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="usuario"
                  label={t("Usuario")}
                  name="usuario"
                  autoComplete="usuario"
                  autoFocus
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("La contraseña es requerida."),
                },
                minLength: {
                  value: 3,
                  message: t("La contraseña debe tener mínimo 3 caracteres."),
                },
              }}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  error={
                    errors?.password?.type === "required" ||
                    errors?.password?.type === "minLength"
                  }
                  helperText={errors.password?.message}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="contraseña"
                  label={t("Contraseña")}
                  id="contraseña"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t(
                            "Cambie la visibilida de la contraseña"
                          )}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Visibility
                              style={{ fill: theme.palette.secondary.main }}
                            />
                          ) : (
                            <VisibilityOff
                              style={{ fill: theme.palette.primary.main }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {loginError !== "" && (
              <p className={classes.loginerror}>{loginError}</p>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              {t("Entrar")}
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(LoginPage);
