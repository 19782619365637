import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import SocioProfileMenuAvatar from "../SocioProfileMenuAvatar/SocioProfileMenuAvatar";
import { Drawer, List, Typography, withTheme } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import SocioProfilePanelArchivoAdjuntos from "../SocioProfilePanelArchivoAdjuntos/SocioProfilePanelArchivoAdjuntos";
import SocioProfilePanelMedidas from "../SocioProfilePanelMedidas/SocioProfilePanelMedidas";
import SocioProfilePanelMetodosPago from "../SocioProfilePanelMetodosPago/SocioProfilePanelMetodosPago";
import ToggleSelect from "../ToggleSelect/ToggleSelect";
import ToggleInput from "../ToggleInput/ToggleInput";

const SocioProfileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocioName = styled(Typography)`
  && {
    padding: 0;
    margin-left: 10px;
    font-size: 2rem;
    cursor: pointer;
  }
`;

const SocioTipo = styled(Typography)`
  && {
    padding: 0;
    margin-left: 10px;
    font-size: 1rem;
  }
`;

interface ICheTypographyStyleProps {
  activo?: boolean;
}
const CheTypography = withTheme(styled(Typography)<ICheTypographyStyleProps>`
  outline: none;
  text-decoration: none;
  position: relative;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  overflow: hidden;
  line-height: 0.75;
  font-size: 1.1rem;
  cursor: pointer;
  && {
    color: ${(props) =>
      props.activo ? props.theme.palette.primary.main : "#fff"};
  }

  &:hover {
    color: #ccc;
  }
  &::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 100%;
    top: 50%;
    margin-top: -8px;
    right: 0;
    background: ${(props) => props.theme.palette.primary.main};
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  &:hover::after {
    transform: translate3d(100%, 0, 0);
  }
  &::before {
    content: attr(data-letters);
    position: absolute;
    z-index: 2;
    overflow: hidden;
    color: ${(props) => props.theme.palette.primary.main};
    white-space: nowrap;
    width: 0%;
    transition: width 0.4s 0.3s;
  }
  &:hover::before {
    width: 100%;
  }
`);
const DrawerWrapper = withTheme(styled(Drawer)`
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.secondary.main};
    padding: 1%;
    width: 40%;
  }
`);

type IProps = {};

const SocioProfileMenu: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const [openDatos, setOpenDatos] = React.useState(false);
  const [openDocs, setOpenDocs] = React.useState(false);
  const [openMdp, setOpenMdp] = React.useState(false);

  return (
    <>
      <SocioProfileMenuWrapper data-testid="SocioProfileMenu">
        <SocioProfileMenuAvatar />
        <SocioName
          variant="h3"
          onClick={() =>
            navigator.clipboard.writeText(
              rootStore.currentSocio?.nombre +
                " " +
                rootStore.currentSocio?.apellido || ""
            )
          }
        >
          <ToggleInput
            initialValue={
              rootStore.currentSocio?.nombre
                ? rootStore.currentSocio?.nombre
                : "Sin datos."
            }
            type="text"
            id="nombre"
            reload={true}
          />
          <ToggleInput
            initialValue={
              rootStore.currentSocio?.apellido
                ? rootStore.currentSocio?.apellido
                : "Sin datos."
            }
            type="text"
            id="apellido"
            reload={true}
          />
        </SocioName>
        <SocioTipo variant="h4">
          <ToggleSelect
            initialValue={
              rootStore.currentSocio?.tipo_socio
                ? rootStore.currentSocio?.tipo_socio
                : "Sin datos."
            }
            id="tipo_socio"
            getOptions={rootStore.getSocioTipoSocios}
          />
        </SocioTipo>
        <List>
          <ListItem>
            <CheTypography variant="h6" data-letters={t("Perfil")} activo={1}>
              {t("Perfil")}
            </CheTypography>
          </ListItem>
          <ListItem>
            <CheTypography
              variant="h6"
              data-letters={t("Documentación")}
              onClick={() => setOpenDocs(true)}
            >
              {t("Documentación")}
            </CheTypography>
          </ListItem>
          <ListItem>
            <CheTypography
              variant="h6"
              data-letters={t("Medidas Antropométricas")}
              onClick={() => setOpenDatos(true)}
            >
              {t("Medidas Antropométricas")}
            </CheTypography>
          </ListItem>
          <ListItem>
            <CheTypography
              variant="h6"
              data-letters={t("Métodos de Pago")}
              onClick={() => setOpenMdp(true)}
            >
              {t("Métodos de Pago")}
            </CheTypography>
          </ListItem>
        </List>
      </SocioProfileMenuWrapper>
      <DrawerWrapper
        anchor="right"
        open={openDatos}
        onClose={() => setOpenDatos(false)}
      >
        <Typography variant="h5" align="center">
          {t("Medidas Antropométricas")}
        </Typography>
        <SocioProfilePanelMedidas />
      </DrawerWrapper>
      <DrawerWrapper
        anchor="right"
        open={openDocs}
        onClose={() => setOpenDocs(false)}
      >
        <Typography variant="h5" align="center">
          {t("Documentación")}
        </Typography>
        <SocioProfilePanelArchivoAdjuntos />
      </DrawerWrapper>
      <DrawerWrapper
        anchor="right"
        open={openMdp}
        onClose={() => setOpenMdp(false)}
      >
        <Typography variant="h5" align="center">
          {t("Métodos de Pago")}
        </Typography>
        <SocioProfilePanelMetodosPago />
      </DrawerWrapper>
    </>
  );
};
export default observer(SocioProfileMenu);
