import { flow, IAnyModelType, types } from "mobx-state-tree";
import { api } from "../api";
import { ActividadSocio } from "./ActividadSocio";
import { Grupo } from "./Grupo";
import { MetodoPago } from "./MetodoPago";
import { Nutricion } from "./Nutricion";
import { PagoSocio } from "./PagoSocio";
import { SocioArchivoAdjunto } from "./SocioArchivoAdjunto";

export const Socio = types
  .model("Socio", {
    id: 0,
    nombre: types.maybeNull(types.string),
    apellido: types.maybeNull(types.string),
    dni: types.maybeNull(types.number),
    numero: types.maybeNull(types.number),
    direccion: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    telefono_linea: types.maybeNull(types.number),
    telefono_celular: types.maybeNull(types.number),
    fecha_nacimiento: types.maybeNull(types.string),
    codigo_postal: types.maybeNull(types.string),
    pais: types.maybeNull(types.string),
    provincia: types.maybeNull(types.string),
    localidad: types.maybeNull(types.string),
    genero: types.maybeNull(types.string),
    foto: types.maybeNull(types.string),
    tipo_socio: types.maybeNull(types.string),
    fecha_inscripcion: types.maybeNull(types.string),
    grupo: types.maybe(types.late((): IAnyModelType => Grupo)),
    actividades: types.array(ActividadSocio),
    pagos: types.array(PagoSocio),
    metodos_pago: types.array(MetodoPago),
    psicologiasocio: types.maybeNull(types.number),
    nutricionsocio: types.maybeNull(types.number),
    adjuntos: types.array(SocioArchivoAdjunto),
    nutricion: types.optional(Nutricion, {}),
  })
  .actions((self) => {
    return {
      patchSocio: flow(function* (field, value) {
        try {
          const result = yield api.patchSocio(self.id, field, value);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      patchNutricion: flow(function* (field, value) {
        try {
          const result = yield api.patchNutricion(self.id, field, value);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioNutricion: flow(function* () {
        try {
          const result = yield api.getSocioNutricion(self.id);
          self.nutricion = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioArchivoAdjuntos: flow(function* () {
        try {
          const result = yield api.getSocioArchivoAdjuntos(self.id);
          if (result.status === 200) {
            self.adjuntos = result.data;
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioArchivoAdjuntos: flow(function* (adjuntoid: number) {
        try {
          const result = yield api.deleteSocioArchivoAdjuntos(
            self.id,
            adjuntoid
          );
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioMetodosPago: flow(function* () {
        try {
          const result = yield api.getSocioMetodosPago(self.id);
          self.metodos_pago = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioMetodosPago: flow(function* (metodopagoid: number) {
        try {
          const result = yield api.deleteSocioMetodosPago(
            self.id,
            metodopagoid
          );
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
    };
  });
