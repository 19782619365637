import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import MUIDataTable, { FilterType } from "mui-datatables";
import { Drawer, Typography, withTheme } from "@material-ui/core";
import AppConfig from "../../config";
import CustomToolbar from "../DataGrid/CustomToolbar";
import SocioActividadForm from "../SocioActividadForm/SocioActividadForm";

const SocioProfilePanelActividadesWrapper = styled.div``;
const DrawerWrapper = withTheme(styled(Drawer)`
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`);
const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
    },
  },
  {
    name: "nombre",
    label: "Nombre",
  },
];

type IProps = {};

const SocioProfilePanelActividades: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const { t } = useTranslation();
  const data = rootStore.currentSocio?.actividades;
  const [open, setOpen] = React.useState(false);

  const create = () => {
    setOpen(true);
  };
  const options = {
    filterType: "textField" as FilterType,
    textLabels: AppConfig.DATATABLE_LABELS,
    customToolbar: () => {
      return <CustomToolbar handleClickCreate={create} />;
    },
  };

  return (
    <>
      <SocioProfilePanelActividadesWrapper data-testid="SocioProfilePanelActividades">
        <MUIDataTable
          title={t("Lista de Actividades")}
          data={data ? data : []}
          columns={columns}
          options={options}
        />
      </SocioProfilePanelActividadesWrapper>
      <DrawerWrapper anchor="left" open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5" align="center">
          {t("Agregar Actividad")}
        </Typography>
        <SocioActividadForm closeback={setOpen} />
      </DrawerWrapper>
    </>
  );
};
export default observer(SocioProfilePanelActividades);
