import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import RootStore from "../../stores/RootStore";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../api";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  withTheme,
  Typography,
} from "@material-ui/core";
import LoadingDot from "../LoadingDot/LoadingDot";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ObsPsicologiaAdjuntos from "../ObsPsicologiaAdjuntos/ObsPsicologiaAdjuntos";

const ObsPsicologiaEditFormWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;
const StyledFormControl = styled(FormControl)`
  width: 250px;
  justify-items: center;
`;
const StyledForm = withTheme(styled("form")`
  width: 100%;
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  }
  & .MuiGrid-item {
    display: flex;
    justify-content: center;
  }
`);

const StyledTextField = withTheme(styled(TextField)`
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`);

type IProps = {
  closeback: (open: boolean) => any;
  data?: any;
};

const ObsPsicologiaEditForm: React.FC<IProps> = (props) => {
  const rootStore = useStore(RootStore);
  const [loading, setLoading] = React.useState(false);
  const [editorState, setEditorState] = React.useState(
    props.data.observaciones
  );
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const result = await api.patchObsPsicologiabyID(props.data.id, data);
    setLoading(false);
  };

  if (loading) {
    return <LoadingDot height={"calc(200px)"} />;
  }

  return (
    <ObsPsicologiaEditFormWrapper data-testid="ObsPsicologiaFormFormWrapper">
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => <ReactQuill theme="snow" {...field} />}
              name="observaciones"
              control={control}
              defaultValue={editorState}
            />
            <Controller
              render={({ field }) => (
                <StyledTextField
                  id="fecha"
                  label={t("Fecha")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
              name="fecha"
              control={control}
              defaultValue={props.data.fecha}
            />
            <Typography>Archivos Adjuntos</Typography>
            <ObsPsicologiaAdjuntos obs_id={props.data.id} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </ObsPsicologiaEditFormWrapper>
  );
};
export default observer(ObsPsicologiaEditForm);
