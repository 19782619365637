export const setToLS = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: string) => {
  const value: any = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: "server",
      message: errors[key as keyof T]!.join(" "),
    });
  });
}

export const openInNewTab = (url: string | null) => {
  if (url) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }
};

export const getFilenameFromURL = (url: string | null) => {
  if (url) {
    return url.substring(url.lastIndexOf("/") + 1);
  }
  return "";
};

export const nicerName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1).replace("_", " ");
};
