import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomToolbar(props: any) {
  const { handleClickPdf, handleClickExcel, handleClickCreate } = props;
  const theme = useTheme();

  return (
    <React.Fragment>
      {handleClickPdf && (
        <Tooltip title={"Exportar PDF"}>
          <IconButton onClick={handleClickPdf}>
            <PictureAsPdfIcon
              style={{ fill: theme.palette.secondary.contrastText }}
            />
          </IconButton>
        </Tooltip>
      )}
      {handleClickExcel && (
        <Tooltip title={"Exportar Excel"}>
          <IconButton onClick={handleClickExcel}>
            <DescriptionIcon
              style={{ fill: theme.palette.secondary.contrastText }}
            />
          </IconButton>
        </Tooltip>
      )}
      {handleClickCreate && (
        <Tooltip title={"Agregar"}>
          <IconButton onClick={handleClickCreate}>
            <AddCircleIcon style={{ fill: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  );
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
