import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { a, useTransition } from "@react-spring/web";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { observer } from "mobx-react";
import { withTheme } from "@material-ui/core";

interface IFWPWrapperStyle {
  backgroundColor: string;
}

const FWPWrapper = withTheme(styled("div")<IFWPWrapperStyle>`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: calc(100vh - 124px);
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.palette.secondary.dark};
`);

type IProps = {
  path?: string;
  backgroundColor?: string;
  testid: string;
};
const FullWidthPage: React.FC<IProps> = (props) => {
  const [oldPath, setOldPath] = useState<string | undefined>("");
  const transition = useTransition(props.children, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  });
  let view: Element | ReactNode | undefined = transition(
    (style, item, t, i) => {
      return (
        <a.div
          style={{
            ...(style as any),
            width: "100%",
            top: 0,
            left: 0,
          }}
          key={i}
        >
          {item}
        </a.div>
      );
    }
  );
  if (oldPath === props.testid) {
    view = props.children;
  } else {
    setOldPath(props.testid);
  }

  return (
    <>
      <Header />
      <FWPWrapper
        data-testid={props.testid}
        backgroundColor={props.backgroundColor}
      >
        {view}
      </FWPWrapper>
      <Footer />
    </>
  );
};

export default observer(FullWidthPage);
