import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getFromLS, setToLS } from "./utils/storage";
import AppConfig from "./config";

import { useCreateStore, useProvider } from "mobx-store-provider";
import { onSnapshot } from "mobx-state-tree";
import RootStore from "./stores/RootStore";
import VolatileStore from "./stores/VolatileStore";
import { observer } from "mobx-react";

import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";

import { Router } from "@reach/router";
import AuthenticatedRoute from "./components/AuthenticatedRoute/AuthenticatedRoute";
import SocioAddPage from "./pages/SocioAddPage/SocioAddPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import SocioListPage from "./pages/SocioListPage/SocioListPage";
import HomePage from "./pages/HomePage/HomePage";
import SocioProfilePage from "./pages/SocioProfilePage/SocioProfilePage";

const rootStore = getFromLS(AppConfig.STORE_NAME);

function App() {
  const { t } = useTranslation();
  const store = useCreateStore(RootStore, rootStore ? rootStore : {});
  const StoreProvider = useProvider(RootStore);
  const volatileStore = useCreateStore(VolatileStore, {});
  const VolatileStoreProvider = useProvider(VolatileStore);
  const [loading, setLoading] = React.useState(true);

  onSnapshot(store, (newSnapshot) => {
    setToLS(AppConfig.STORE_NAME, newSnapshot);
  });
  if (!rootStore) {
    setToLS(AppConfig.STORE_NAME, store);
  }

  React.useEffect(() => {
    volatileStore.verify().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="App">
      <Helmet>
        <title>
          {AppConfig.APP_NAME} / {t("Club Manager")}
        </title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <VolatileStoreProvider value={volatileStore}>
          <StoreProvider value={store}>
            <Router>
              <AuthenticatedRoute as={HomePage} path="/" />
              <AuthenticatedRoute as={SocioListPage} path="/socios" />
              <AuthenticatedRoute as={SocioAddPage} path="/socios/nuevo" />
              <AuthenticatedRoute
                as={SocioProfilePage}
                path="/socio/:socioid"
              />
              <LoginPage path="/login" />
            </Router>
          </StoreProvider>
        </VolatileStoreProvider>
      </ThemeProvider>
    </div>
  );
}

export default observer(App);
