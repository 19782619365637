import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStore } from "mobx-store-provider";
import VolatileStore from "../../stores/VolatileStore";
import styled from "styled-components";
import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Hidden from "@material-ui/core/Hidden";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";

const HeaderWrapper = styled.div`
  display: flex;
`;

const StyledTypography = styled(Typography)`
  text-transform: capitalize;
  padding: 10px;
`;

type IProps = {};

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "#19437F",
      color: "#fff",
    },
  },
});

const Header: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const volatileStore = useStore(VolatileStore);
  const { t } = useTranslation();
  const handleLogOut = () => {
    volatileStore.logOut();
    navigate("/login");
  };
  return (
    <HeaderWrapper data-testid="Header">
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar disableGutters={true}>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={4}>
                <HeaderLogo />
              </Grid>
              <Grid item sm={3} xs={8}>
                <HeaderSearch />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Button
                  className={classes.button}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => navigate("/socios")}
                >
                  <PeopleAltIcon />
                  <Hidden smDown>
                    <StyledTypography variant="body2">
                      {t("Socios")}
                    </StyledTypography>
                  </Hidden>
                </Button>
                <Button
                  className={classes.button}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <LocalActivityIcon />
                  <Hidden smDown>
                    <StyledTypography variant="body2">
                      {t("Actividades")}
                    </StyledTypography>
                  </Hidden>
                </Button>
                <Button
                  className={classes.button}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <EventAvailableIcon />
                  <Hidden smDown>
                    <StyledTypography variant="body2">
                      {t("Calendario")}
                    </StyledTypography>
                  </Hidden>
                </Button>
                <Button
                  className={classes.button}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AttachFileIcon />
                  <Hidden smDown>
                    <StyledTypography variant="body2">
                      {t("Inventario")}
                    </StyledTypography>
                  </Hidden>
                </Button>
                <Button
                  className={classes.button}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleLogOut}
                >
                  <ExitToAppIcon />
                  <Hidden smDown>
                    <StyledTypography variant="body2">
                      {t("Salir")}
                    </StyledTypography>
                  </Hidden>
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </HeaderWrapper>
  );
};
export default observer(Header);
