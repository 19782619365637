import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configure } from "axios-hooks";
import LRU from "lru-cache";
import axios from "./axios";

const cache = new LRU({ max: 10 });

configure({ axios, cache });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
